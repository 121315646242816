<template>
    <Modal
        :value="show"
        @input="close"
        :modalModifiers="['allow-overflow']"
        >
            <template slot="header">Transfer Ownership</template>
            <form @submit.prevent="transferOwnership()">
                <fieldset class="field">
                    <label class="label is-small">Business:</label>
                    <auto-complete
                        placeholder="Business Name"
                        v-model="business"
                        endpoint="/admin/businesses/load?query={term}&autocomplete=true"
                        display="name"
                        :set-on-blur="false"
                        @input="businessSet"
                        >
                            <template #result="{result}">
                                <div class="columns is-marginless is-mobile is-gapless is-multiline is-size-7-half has-text-grey autocomplete-results">
                                    <div class="column is-12">
                                        <span class="has-text-grey-darker has-text-weight-semibold">{{result.name}}</span> - <span class="has-text-grey is-size-7-half">{{result.city}}, {{result.state}}</span>
                                    </div>
                                </div>
                            </template>
                    </auto-complete>
                </fieldset>
                <fieldset class="field">
                    <label class="label is-small">User:</label>
                    <auto-complete
                        placeholder="User Name"
                        v-model="user"
                        :endpoint="`/manage/business/${businessId}/all?query={term}&searchType=active&autocomplete=true`"
                        display="fullName"
                        :set-on-blur="false"
                        :disabled="business == null"
                        >
                            <template #result="{result}">
                                <div class="columns is-marginless is-mobile is-gapless is-multiline is-size-7-half has-text-grey autocomplete-results">
                                    <div class="column">
                                        <span class="has-text-grey-darker has-text-weight-semibold is-capitalized">{{result.fullName}}</span>
                                    </div>
                                    <div class="column is-narrow is-capitalized">
                                        {{ result.role }}
                                    </div>
                                </div>
                            </template>
                    </auto-complete>
                </fieldset>
                <div class="buttons is-right">
                    <button
                        class="button is-primary"
                        :class="{'is-loading': processing}"
                        :disabled="formDisabled"
                        >
                            Transfer
                    </button>
                </div>
            </form>
    </Modal>
</template>

<script>
import Modal from '@components/Modal/ModalComponent.vue';
import AutoComplete from '@components/AutoComplete/AutoComplete.vue';
import { axios } from '@master';

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        bookingId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            show: false,
            processing: false,
            business: null,
            loadingUsers: false,
            user: null
        }
    },
    methods: {
        close() {
            this.show = false;
            this.$emit('input', false);
        },
        businessSet(e) {
            if(e == null) {
                this.business = null;
                this.user = null;
            }
        },
        async transferOwnership() {
            this.processing = true;

            try {
                const payload = {
                    businessId: this.business.id,
                    userId: this.user.id
                };
                await axios.post(`/admin/bookings/${this.bookingId}/transfer`, payload);
                this.close();
                this.swalSuccess('Booking has been transfered successfully.', 'Success', [
                    { value: "Close", handler: () => window.location.reload() }
                ]);
            } catch (e) {
                console.error(e);
                const {data} = e;
                this.swalError(data.message, 'Error');
            }

            this.processing = false;
        }
    },
    computed: {
        formDisabled() {
            if(this.business == null || this.user == null || this.processing) {
                return true;
            }
            return false;
        },
        businessId() {
            if(this.business != null) {
                return this.business.id;
            }
            return null;
        }
    },
    watch: {
        value(show) {
            this.show = show;
            this.business = null;
            this.user = null;
        }
    },
    components: {
        Modal,
        AutoComplete
    }
}
</script>
