<template>
    <div class="message-list">
        <alert v-for="(alert, index) in alerts" :key="index" :alert="alert"></alert>
    </div>
</template>

<script>
import Alert from './Alert.vue';

export default {
    data() {
        return {
            alerts: []
        }
    },
    methods: {
        addAlert(alert) {
            alert.id = Math.round(Math.random() * 10000);
            this.alerts.push(alert);
        }
    },
    mounted() {
        this.$root.$on('addAlert', (alert) => {
            this.addAlert(alert);
        });
        this.$root.$on('removeAlert', (alertId) => {
            const alertLocation = this.alerts.map(alert => alert.id).indexOf(alertId);
            this.alerts.splice(alertLocation, 1);
        });
    },
    components: {
        'alert': Alert
    }
}
</script>

<style lang="scss">
.message-list {
    bottom: 0;
    left: 0;
    max-width: 350px;
    position: fixed;
    width: 100%;
    z-index: 100;
    .message {
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
        margin-bottom: 1rem;
        margin-left: 1rem;
    }
}
</style>
