<template>
    <div class="field has-addons">
        <div class="control is-expanded has-icons-left">
            <input
                :type="showPassword ? 'text' : 'password'"
                :disabled="disabled"
                class="input"
                :class="`is-${size}`"
                :name="name"
                v-model="newValue"
                :placeholder="placeholder"
                @keyup="$emit('input', newValue)"
                @blur="$emit('input', newValue)"
                :autocomplete="autocomplete"/>
            <span class="icon is-small is-left">
                <i class="fas fa-unlock-alt"></i>
            </span>
        </div>
        <div class="control">
            <button
                type="button"
                class="button is-icon has-text-grey-light"
                :disabled="disabled"
                :class="`is-${size}`"
                @mousedown="showPassword = true"
                @touchstart="showPassword = true"
                @mouseup="showPassword = false"
                @touchend="showPassword = false"
                tabindex="-1"
                >
                <span class="icon is-small">
                    <i class="fa" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: ""
        },
        autocomplete: {
            type: String,
            default: "off"
        }
    },
    data() {
        return {
            newValue: null,
            showPassword: false
        };
    },
    mounted() {
        this.newValue = this.value;
    },
    watch: {
        value(val) { this.newValue = val; }
    }
}
</script>