<template>
    <div class="wrapper" :class="{'hover': value == null, 'show': value == true}">
        <div class="tooltip" :class="[position]" :style="custom ? 'padding:0!important;' : 'background-color: rgba(0, 0, 0, 0.75);border-radius: 4px;'">
            <slot name="tooltip" />
        </div>
        <span :class="{'text': underline}">
            <slot name="content" />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: null
        },
        position: {
            type: String,
            default: "left"
        },
        underline: {
            type: Boolean,
            default: true
        },
        custom: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/all";
.wrapper {
    display: inline-block;
    position: relative;
    .tooltip {
        color: hsl(0, 0%, 96%);
        display: none;
        font-size: 12px;
        padding: .25rem .75rem;
        position: absolute;
        text-align: left;
        white-space: nowrap;
        z-index: 50;
        &:after {
            border: solid 6px transparent;
            content: "";
            height: 0;
            position: absolute;
            width: 0;
        }
        &.left {
            top: 50%;
            right: calc(100% + 8px);
            transform: translateY(-50%);
            &:after {
                border-left: solid 6px rgba(0, 0, 0, 0.75);
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &.right {
            top: 50%;
            left: calc(100% + 8px);
            transform: translateY(-50%);
            &:after {
                border-right: solid 6px rgba(0, 0, 0, 0.75);
                right: 100%;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &.top {
            bottom: calc(100% + 8px);
            left: 50%;
            transform: translateX(-50%);
            &:after {
                border-top: solid 6px rgba(0, 0, 0, 0.75);
                left: 50%;
                top: 100%;
                transform: translateX(-50%);
            }
        }
        &.bottom {
            top: calc(100% + 8px);
            left: 50%;
            transform: translateX(-50%);
            &:after {
                border-bottom: solid 6px rgba(0, 0, 0, 0.75);
                left: 50%;
                bottom: 100%;
                transform: translateX(-50%);
            }
        }
    }
    .text {
        text-decoration: underline dotted;
    }
}
@include desktop() {
    .wrapper.hover:hover, .wrapper.show {
        .tooltip {
            display: block;
        }
    }
}
</style>
