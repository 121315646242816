<template>
    <div class="box is-paddingless is-marginless login-window">
        <div class="content is-marginless">
            <h1 class="is-size-4 has-text-grey-dark has-text-weight-semibold has-text-centered is-uppercase">Welcome back.</h1>
            <p class="is-size-7 has-text-grey">Sign in to find the best deals on flights.</p>
            <form @submit.prevent="doLogin($event)" novalidate>
                <div class="field">
                    <div class="control has-icons-left">
                        <input
                            type="email"
                            class="input"
                            v-model="email"
                            name="email"
                            placeholder="Email Address"
                            required
                            v-validate.disable="'required'"
                            data-vv-as="email address"
                            />
                        <span class="icon is-small is-left">
                            <i class="fas fa-envelope"></i>
                        </span>
                    </div>
                    <p class="help is-danger" v-show="errors.has('email')">{{ errors.first('email') }}</p>
                </div>
                <password
                    v-model="password"
                    placeholder="Password"
                    name="password"
                    v-validate.disable="'required'"
                    data-vv-as="password"
                    style="margin-bottom:0;"
                    ></password>
                <p class="help is-danger" v-show="errors.has('password')">{{ errors.first('password') }}</p>

                <p class="has-text-right">
                    <a href="/password/reset" class="has-text-grey is-size-7">Forgot Password?</a>
                </p>

                <div class="buttons">
                    <button class="button is-primary is-fullwidth" type="submit" :disabled="loading" :class="{'is-loading': loading}">Log In</button>
                </div>
            </form>
        </div>
        <div class="columns is-vcentered has-background-white-ter is-marginless is-mobile">
            <div class="column">
                <span class="has-text-grey">Don't have an account?</span>
            </div>
            <div class="column is-narrow">
                <a href="/register" class="button is-primary is-outlined">Register Now</a>
            </div>
        </div>
    </div>
</template>

<script>
import PasswordComponent from "../Password/PasswordComponent.vue";

import GoogleAnalytics from "@mixins/analytics/index.js";
import { axios } from '@master';

export default {
    props: {
        resetErrors: {
            type: Boolean,
            default: false
        }
    },
    mixins: [GoogleAnalytics],
    data() {
        return {
            loading: false,
            email: '',
            password: ''
        }
    },
    methods: {
        doLogin() {
            this.errors.clear();
            this.$validator.validate().then(valid => {
                if(!valid) {
                    return;
                }

                this.loading = true;

                axios.post(`/login`, {
                    email: this.email,
                    password: this.password
                }).then(response => {
                    this.gaEvent('engagement', 'login', 'Login', { method: 'internal' });
                    axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrf;
                    document.querySelector('meta[name="csrf-token"]').setAttribute('content', response.data.csrf);
                    this.$emit('success', response.data);
                }).catch(error => {
                    this.loading = false;
                    if(error.status == 422) {
                        let errors = error.data.errors;
                        for(let field in errors) {
                            this.$validator.errors.add({
                                field: field,
                                msg: errors[field][0],
                                scope: this.$options.scope
                            });
                        }
                    }
                });
            });
        },
    },
    watch: {
        resetErrors(reset) {
            if(reset == true) {
                this.$validator.reset();
            }
        }
    },
    components: {
        'password': PasswordComponent
    }
}
</script>
