<template>
    <div class="message" :class="alert.modifiers">
        <div class="message-header" v-if="alert.title">
            <p>@{{ alert.title }}</p>
        </div>
        <div class="message-body">
            {{ alert.message }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        alert: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        clearTimer(timerId) {
            clearTimeout(timerId);
        }
    },
    mounted() {
        const timerId = setTimeout(() => {
            this.clearTimer(timerId);
            this.$root.$emit('removeAlert', this.alert.id);
        }, 5000);
    }
}
</script>
