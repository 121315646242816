<template>
    <div v-show="isActive"><slot/></div>
</template>

<script>
    export default {
        props: {
            name: String|Number,
            icon: String
        },
        data() {
            return {
                isActive: false
            }
        },
        created() {
            this.$parent.tabList.push(this);
        }
    }
</script>