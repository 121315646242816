import List from './List.vue';

export default {
    install(Vue, opts) {
        const root = new Vue({
            render: createElement => createElement(List)
        });
        root.$mount(document.body.appendChild(document.createElement('div')));

        Vue.mixin({
            methods: {
                addAlert(message, modifiers=null, title=null) {
                    root.$emit('addAlert', {
                        message,
                        modifiers,
                        title
                    });
                },
            }
        });
    }
}
