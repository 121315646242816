<template>
    <Modal
        :content-modifiers="['is-paddingless']"
        :modal-modifiers="['ticketing-modal']"
        :value="show"
        @input="(value) => $emit('input', value)"
        >
        <template #header>
            Edit Vendor Bills
        </template>
        <template v-if="!loaded">
            <BoxLoader :value="true">
                <br>
                <br>
                <br>
            </BoxLoader>
            <p class="py-2 has-text-centered has-text-grey-light has-text-weight-light is-size-4">Generating Vendor Bills</p>
        </template>
        <template v-else>
            <p v-if="vendorBills.length == 0" class="py-5 px-5 has-text-grey">No Vendor Bills associated with Booking</p>
            <form @submit.prevent="() => markTicketed()">
                <div v-for="(vendorBill, vendorBillIndex) in vendorBills" :key="vendorBillIndex">
                    <div class="px-3 py-3">
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field vendor-bill-type">
                                    <label class="label is-small">
                                        <template v-if="vendorBill.vendorType == 'carrier'">Carrier</template>
                                        <template v-else-if="vendorBill.vendorType == 'supplier'">Supplier</template>
                                        <template v-else-if="vendorBill.vendorType == 'business'">Business</template>
                                    </label>
                                    <div class="control">
                                        <template v-if="vendorBill.vendorType == 'business'">
                                            <auto-complete
                                                placeholder="Business Name"
                                                v-model="vendorBill.selected"
                                                endpoint="/admin/businesses/load?query={term}&autocomplete=true"
                                                display="name"
                                                :set-on-blur="false"
                                                :disabled="submitting"
                                                >
                                                    <template #result="{result}">
                                                        <div class="columns is-marginless is-mobile is-gapless is-multiline is-size-7-half has-text-grey autocomplete-results">
                                                            <div class="column is-12">
                                                                <span class="has-text-grey-darker has-text-weight-semibold">{{result.name}}</span> - <span class="has-text-grey is-size-7-half">{{result.city}}, {{result.state}}</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                            </auto-complete>
                                        </template>
                                        <template v-else-if="vendorBill.vendorType == 'supplier'">
                                            <div class="select is-fullwidth">
                                                <select
                                                    v-model="vendorBill.selected"
                                                    :disabled="submitting"
                                                    >
                                                    <option
                                                        v-for="(office, officeIndex) in offices"
                                                        :key="officeIndex"
                                                        :value="office.identifier"
                                                        >
                                                        {{ office.description }} ({{ office.identifier }})
                                                    </option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                        </template>
                                        <template v-else-if="vendorBill.vendorType == 'carrier'">
                                            <div class="select is-fullwidth">
                                                <select
                                                    v-model="vendorBill.selected"
                                                    :disabled="submitting"
                                                    >
                                                    <option
                                                        v-for="(carrier, carrierIndex) in carriers"
                                                        :key="carrierIndex"
                                                        :value="carrier.identifier"
                                                        >
                                                        {{ carrier.description }} ({{ carrier.identifier }})
                                                    </option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                        </template>
                                    </div>
                                </div>

                                <div class="field vendor-bill-amount" v-if="vendorBill.selected == 'other'">
                                    <label class="label is-small">
                                        <template v-if="vendorBill.vendorType == 'carrier'">Carrier Code</template>
                                        <template v-else-if="vendorBill.vendorType == 'supplier'">Office ID</template>
                                    </label>
                                    <div class="control">
                                        <input
                                            type="text"
                                            class="input"
                                            v-model="vendorBill.vendorCode"
                                            :disabled="submitting"
                                            >
                                    </div>
                                </div>

                                <div class="field vendor-bill-amount">
                                    <label class="label is-small">Amount</label>
                                    <div class="control has-icons-left">
                                        <span class="icon is-small is-right">
                                            <i class="fas fa-dollar-sign"></i>
                                        </span>
                                        <input
                                            type="text"
                                            class="input"
                                            v-model="vendorBill.amount"
                                            :disabled="submitting"
                                            >
                                    </div>
                                </div>
                                <div class="field vendor-bill-method" v-if="vendorBill.vendorType == 'carrier' || vendorBill.vendorType == 'supplier'">
                                    <label class="label is-small">Payment Method</label>
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select
                                                v-model="vendorBill.paymentMethod"
                                                v-validate="'required'"
                                                data-vv-name="paymentMethod"
                                                data-vv-as="payment method"
                                                :data-vv-scope="`payment_method_${vendorBillIndex}`"
                                                >
                                                <option
                                                    v-for="(paymentMethod, paymentMethodIndex) in paymentMethods"
                                                    :key="paymentMethodIndex"
                                                    :value="paymentMethodIndex"
                                                    >
                                                        {{ paymentMethod }}
                                                </option>
                                            </select>
                                            <p class="help is-danger" v-show="errors.has(`payment_method_${vendorBillIndex}.paymentMethod`)">{{ errors.first(`payment_method_${vendorBillIndex}.paymentMethod`) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="field vendor-bill-trash">
                                    <label class="label is-small">&nbsp;</label>
                                    <div class="control">
                                        <button
                                            class="button is-light is-inverted has-text-grey-light"
                                            @click="removeVendorBill(vendorBillIndex)"
                                            :disabled="submitting"
                                            type="button"
                                            >
                                            <span class="icon is-small">
                                                <span class="fas fa-trash fa-xs"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field is-horizontal">
                            <div class="field-body">
                                <div class="field">
                                    <label class="label is-small">Description:</label>
                                    <div class="control is-expanded">
                                        <span
                                            class="input is-size-7-half has-text-grey-dark"
                                            contenteditable
                                            role="textbox"
                                            v-html="vendorBill.description"
                                            @blur="(event) => updateDescription(vendorBill, event)"
                                            >
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="is-marginless" v-if="vendorBillIndex < vendorBills.length - 1">
                </div>
                <hr>
                <div class="is-clearfix pt-2 pb-4 px-4">
                    <div class="dropdown is-up" :class="{'is-hoverable': !submitting}">
                        <div class="dropdown-trigger">
                            <button
                                class="button"
                                :disabled="submitting"
                                type="button"
                                >
                                <span>Add Vendor Bill</span>
                                <span class="icon is-small">
                                    <i class="fas fa-angle-up" aria-hidden="true"></i>
                                </span>
                            </button>
                        </div>
                        <div class="dropdown-menu">
                            <div class="dropdown-content">
                                <a @click="addVendorBill('supplier')" class="dropdown-item">Add Supplier Vendor Bill</a>
                                <a @click="addVendorBill('carrier')" class="dropdown-item">Add Carrier Vendor Bill</a>
                                <a @click="addVendorBill('business')" class="dropdown-item">Add Business Vendor Bill</a>
                            </div>
                        </div>
                    </div>
                    <button
                        class="button is-primary is-marginless is-pulled-right"
                        :disabled="submitting"
                        :class="{'is-loading': submitting}"
                        >
                        Mark Ticketed
                    </button>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script>
import Modal from '@components/Modal/ModalComponent.vue';
import AutoComplete from '@components/AutoComplete/AutoComplete.vue';
import BoxLoader from '@components/Loader/BoxLoaderComponent.vue';
import { axios } from '@master';

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        bookingId: {
            type: String,
            default: null
        },
        offices: {
            type: Object|Array,
            default: null
        },
        carriers: {
            type: Object|Array,
            default: null
        }
    },
    data() {
        return {
            show: false,
            loaded: false,
            paymentMethods: {},
            vendorBills: [],
            typeCode: null,
            invoiceId: null,
            submitting: false
        }
    },
    methods: {
        async ticketing() {
            this.loaded = false;
            this.vendorBills = [];
            try {
                const response = (await axios.get(`/admin/bookings/${this.bookingId}/ticketing`)).data;
                this.paymentMethods = response.paymentMethods;
                this.vendorBills = response.vendorBills;
                this.invoiceId = response.invoiceId;
                this.typeCode = response.typeCode;

                this.vendorBills.map(vendorBill => {
                    this.$set(vendorBill, 'selected', vendorBill.vendorCode);
                    vendorBill.paymentMethod = '';
                    return vendorBill;
                });

            } catch (error) {

            }
            this.loaded = true;
        },
        addVendorBill(type) {
            this.vendorBills.push({
                vendorType: type,
                vendorCode: '',
                bookingId: this.bookingId,
                invoiceId: this.invoiceId,
                typeCode: this.typeCode,
                description: '',
                amount: 0,
            });
        },
        removeVendorBill(index) {
            this.vendorBills.splice(index, 1);
        },
        updateDescription(vendorBill, event) {
            vendorBill.description = event.target.innerHTML;
        },
        async markTicketed() {
            const valid = await this.$validator.validate();
            if(!valid) {
                return;
            }

            this.submitting = true;

            try {
                const vendorBills = this.vendorBills.map(vendorBill => {
                    if(vendorBill.selected != 'other' && vendorBill.vendorType != 'business') {
                        vendorBill.vendorCode = vendorBill.selected;
                    }
                    if(vendorBill.vendorType == 'business') {
                        vendorBill.vendorCode = ''+vendorBill.selected.id;
                        vendorBill.paymentMethod = 'other';
                    }
                    if(vendorBill.paymentMethod == 'other') {
                        vendorBill.paymentType = 'other';
                    } else {
                        vendorBill.paymentType = 'ccTransaction';
                    }
                    return vendorBill;
                });
                const payload = { vendorBills };
                await axios.post(`/admin/bookings/${this.bookingId}/ticketed`, payload);
                this.show = false;
                this.swalSuccess('Booking has been marked ticketed successfully!', null, [
                    {
                        value: "Close", handler: () => {
                            window.location.reload();
                        }
                    }
                ]);
            } catch ({data}) {
                this.swalError(data.message);
            }
            this.submitting = false;
        }
    },
    mounted() {
        this.show = this.value;
    },
    watch: {
        value(newValue) {
            this.show = newValue;
            if(this.show) {
                this.ticketing();
            }
        }
    },
    components: {
        Modal,
        AutoComplete,
        BoxLoader
    }
}
</script>

<style lang="scss">
.vendor-bill-method, .vendor-bill-type, .vendor-bill-amount {
    flex: 1;
}
.vendor-bill-trash {
    flex-grow: 0!important;
}
span[role="textbox"] {
    height: auto;
    max-height: none;
    min-height: auto;
    white-space: break-spaces;
}
.ticketing-modal .modal-card {
    width: 800px;
}
</style>
