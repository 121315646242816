<template>
    <div class="field has-addons">
        <p class="control">
            <button type="button" class="button is-inverted" @click="updateValue('dec');" :disabled="newValue <= min || mindisabled">
                <span class="icon is-small">
                    <i class="fas fa-minus fa-xs"></i>
                </span>
            </button>
        </p>
        <div class="control is-expanded">
            <input class="input has-text-centered" type="number" :min="min" :max="max" v-model="newValue" :tabindex="tabindex" :autofocus="autofocus" @keyup="handleKeyUp" @blur="checkNumber" >
        </div>
        <p class="control">
            <button type="button" class="button is-inverted" @click="updateValue('inc');" :disabled="newValue >= max || maxdisabled">
                <span class="icon is-small">
                    <i class="fas fa-plus fa-xs"></i>
                </span>
            </button>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        mindisabled: Boolean,
        maxdisabled: Boolean,
        tabindex: {
            type: Number,
            default: null
        },
        autofocus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newValue: 0
        }
    },
    methods: {
        updateValue(dir) {
            if(dir == 'inc') this.newValue++;
            if(dir == 'dec') this.newValue--;
            this.$emit('change', this.newValue);
        },
        handleKeyUp($event) {
            if(this.newValue != "") {
                this.$emit('change', parseInt(this.newValue));
            }
        },
        checkNumber() {
            if(this.newValue < this.min || this.newValue > this.max) {
                this.newValue = 0;
                this.$emit('change', this.newValue);
            }
        }
    },
    mounted() {
        this.newValue = this.value;
    },
    watch: {
        value(value) {
            this.newValue = value;
        }
    }
}
</script>

<style>
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
</style>