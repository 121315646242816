import Login from './Login.vue';

export default {
    install(Vue, opts) {
        const root = new Vue({
            render: createElement => createElement(Login)
        });
        root.$mount(document.body.appendChild(document.createElement('div')));

        Vue.mixin({
            methods: {
                openLoginModal(callback = null) {
                    root.$emit('showLoginModal', true);
                    root.$on('success', ($event) => {
                        if(callback != null) {
                            (callback)();
                        }
                    });
                },
            }
        });
    }
}
