<template>
    <div class="background" v-show="this.value">
        <img
            :src="image"
            :alt="this.$slots.default[0].text"
            class="loading-icon"
            />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: true
        },
        searching: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        image() {
            if(this.searching) {
                return '/img/searching.gif';
            }
            return '/img/loading.gif';
        }
    }
}
</script>

<style lang="scss" scoped>
.background {
    background: rgba(10, 10, 10, 0.86);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 40;
}
.loading-icon {
    height: 100%;
    left: 50%;
    max-height: 150px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 41;
}
</style>
