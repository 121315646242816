export default {
    install(Vue, opts) {
        Vue.component('root-properties', {
            props: {
                properties: Number|String|Object|Array
            },
            template: "<span></span>",
            mounted() {
                for(let key in this.properties) {
                    this.$parent[key] = this.properties[key];
                }
            }
        });
    }
}