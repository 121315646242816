<template>
    <div>
        <div class="google-map" ref="googleMap" style="height:435px;"></div>
        <template v-if="this.google && this.map"></template>
    </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import MarkerClusterer from '@google/markerclusterer';

export default {
    props: {
        points: {
            type: Array,
            default: () => { return []; }
        }
    },
    data() {
        return {
            apiKey: 'AIzaSyBVWKufl8eyPFz8Yg8ODrdNETLwslMensM',
            setup: false,
            google: null,
            map: null,
            markers: [],
            nonMarkers: [],
            markerCluster: null,
            infoWindows: [],
            currentInfoWindow: null
        }
    },
    methods: {
        initializeMap() {
            const mapContainer = this.$refs.googleMap;
            this.map = new this.google.maps.Map(mapContainer, {
                disableDefaultUI: true,
                zoomControl: false,
                gestureHandling: 'cooperative',
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
            });
            this.createResetButton();
            this.createZoomButtons();
        },
        setMapZoomLimit() {
            google.maps.event.addListener(this.map, 'zoom_changed', () => {
                let zoomChangedListener = google.maps.event.addListener(this.map, 'bounds_changed', (event) => {
                    if(this.map.getZoom() > 7 && this.setup == false) {
                        this.map.setZoom(7);
                        this.setup = true;
                    } else if(this.map.getZoom() > 11) {
                        this.map.setZoom(11)
                    }
                    google.maps.event.removeListener(zoomChangedListener);
                });
            });
        },
        createResetButton() {
            let controlButton = document.createElement('button');
            controlButton.index = 1;
            controlButton.setAttribute('class', 'button is-primary');
            controlButton.style.boxShadow = '0px 1px 4px -1px rgba(0, 0, 0, 0.3)';
            controlButton.style.borderRadius = '2px';
            controlButton.style.marginRight = '10px';
            controlButton.style.marginTop = '10px';
            controlButton.style.fontWeight = '500';
            controlButton.innerHTML = 'View All';

            controlButton.addEventListener('click', () => {
                if(this.currentInfoWindow != null) {
                    this.infoWindows[this.currentInfoWindow].close();
                }
                this.markerCluster.fitMapToMarkers();
            });
            this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlButton);
        },
        createZoomButtons() {
            let zoomInButton = document.createElement('button');
            zoomInButton.index = 1;
            zoomInButton.setAttribute('class', 'button is-primary');
            zoomInButton.style.boxShadow = '0px 1px 4px -1px rgba(0, 0, 0, 0.3)';
            zoomInButton.style.borderRadius = '2px';
            zoomInButton.style.height = '36px';
            zoomInButton.style.width = '36px';
            zoomInButton.style.marginRight = '10px';
            zoomInButton.style.marginBottom = '10px';
            zoomInButton.innerHTML = '<span class="icon is-small"><i class="fas fa-plus fa-xs"></i></span>';

            let zoomOutButton = document.createElement('button');
            zoomOutButton.index = 1;
            zoomOutButton.setAttribute('class', 'button is-primary');
            zoomOutButton.style.boxShadow = '0px 1px 4px -1px rgba(0, 0, 0, 0.3)';
            zoomOutButton.style.borderRadius = '2px';
            zoomInButton.style.height = '36px';
            zoomInButton.style.width = '36px';
            zoomOutButton.style.marginRight = '10px';
            zoomOutButton.style.marginBottom = '10px';
            zoomOutButton.innerHTML = '<span class="icon is-small"><i class="fas fa-minus fa-xs"></i></span>';

            zoomInButton.addEventListener('click', () => {
                this.map.setZoom(this.map.getZoom()+1);
            });
            zoomOutButton.addEventListener('click', () => {
                this.map.setZoom(this.map.getZoom()-1);
            });

            this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomOutButton);
            this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomInButton);

        },
        clearMap() {
            if(this.markerCluster != null) {
                this.markerCluster.clearMarkers();
            }
            if(this.markers.length > 0) {
                this.markers.forEach(marker => {
                    marker.setMap(null);
                });
            }
            this.setup = false;
            this.markers.length = 0;
            this.infoWindows.length = 0;
            this.currentInfoWindow = null
        },
        drawMap() {
            this.points.forEach((point, index) => {
                if(point.latitude != null && point.longitude != null) {
                    this.createMarker(point, index);
                    this.createInfoWindows(point, index);
                } else {
                    this.nonMarkers.push(point.bookings.map(booking => booking.id));
                }
            });
            if(this.markers.length > 0) {
                this.clusterMarkers();
                if(this.map.controls[google.maps.ControlPosition.RIGHT_TOP].length <= 0) {
                    this.createResetButton();
                }
                if(this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].length <= 0) {
                    this.createZoomButtons();
                }
                let options = {
                    gestureHandling: 'cooperative',
                    draggable: true,
                };
                this.map.setOptions(options);
            } else {
                let options = {
                    zoom: 4,
                    center: {lat: 37.0902, lng: -95.7129},
                    gestureHandling: 'none',
                    draggable: false
                };
                this.map.setOptions(options);
                this.map.controls[google.maps.ControlPosition.RIGHT_TOP].clear();
                this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
                this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear();
            }
        },
        createMarker(point, index) {
            const { Marker } = google.maps;
            let marker = new Marker({
                id: index,
                position: {lat: point.latitude, lng: point.longitude},
                map: this.map,
                icon: {
                    anchor: new google.maps.Point(26, 26),
                    url: '/img/clusters/m0.png'
                },
                bookingIds: point.bookings.map(booking => booking.id),
                numTravelers: point.numTravelers,
                label: {
                    fontSize: '11px',
                    fontWeight: '600',
                    text: `${point.numTravelers}`
                }
            });
            marker.setMap(this.map);
            this.markers[index] = marker;
        },
        createInfoWindows(point, index) {
            this.infoWindows[index] = new this.google.maps.InfoWindow({
                content: point.infoWindow.join('')
            });
            google.maps.event.addListener(this.markers[index], 'click', () => {
                if(this.currentInfoWindow != null) {
                    this.infoWindows[this.currentInfoWindow].close();
                }
                this.currentInfoWindow = index;
                this.infoWindows[index].open(this.map, this.markers[index]);

                this.map.setCenter({lat: point.latitude, lng: point.longitude});
                this.map.setZoom(7);
            });
            google.maps.event.addListener(this.infoWindows[index], 'closeclick', () => {
                this.markerCluster.fitMapToMarkers();
            });
        },
        clusterMarkers() {
            if(this.markerCluster == null) {
                this.markerCluster = new MarkerClusterer(this.map, this.markers, { imagePath: '/img/clusters/m' });
                this.markerCluster.setGridSize(25);
            } else {
                this.markerCluster.setMap(this.map);
                this.markerCluster.addMarkers(this.markers);
            }
            this.markerCluster.setCalculator((markers, numStyles) => {
                let total = markers.reduce((carry, marker) => {
                    return carry + marker.numTravelers;
                }, 0);
                return {
                    text: `${total}`,
                    index: 1
                };
            });
            this.markerCluster.fitMapToMarkers();
        },
        filterList() {
            let ids = [];
            this.markers.forEach((marker) => {
                if(this.map.getBounds().contains(marker.getPosition())) {
                    ids.push(marker.bookingIds);
                }
            });
            this.$root.$emit('filterTripList', {markers: ids.flat(1), nonMarkers: this.nonMarkers.flat(1)});
        }
    },
    async mounted() {
        const loader = new Loader({
            apiKey: this.apiKey,
            version: 'weekly'
        });

        this.google = {};
        this.google.maps = await loader.importLibrary('maps');
        await loader.importLibrary('marker');

        this.initializeMap();
        this.setMapZoomLimit();
        this.drawMap();

        google.maps.event.addListener(this.map, 'idle', () => {
            this.filterList();
        });

        this.$root.$on('redrawMap', () => {
            this.$nextTick(() => {
                this.clearMap();
                this.drawMap();
            });
        });

        this.$root.$on('clearMap', () => {
            this.clearMap();
        });
    }
}
</script>
