<template>
    <div>
        <h1 class="box-title is-size-4 is-uppercase has-text-weight-light has-text-grey" v-if="label">{{label}}</h1>
        <div class="box">

            <div class="columns is-centered" v-if="dates.from && dates.to">
                <div class="column is-narrow is-relative" v-auto-close="autoClose">
                    <div class="field has-addons is-marginless">
                        <button class="button is-dark is-inverted is-small" @click="prevDateRange()">
                            <span class="icon is-small">
                                <i class="fas fa-angle-left"></i>
                            </span>
                        </button>
                        <button class="button is-dark is-inverted is-small has-text-weight-semibold"
                                @click="showMenu = !showMenu">
                            <span>{{ dateLabel }}</span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down"></i>
                            </span>
                        </button>
                        <button class="button is-dark is-inverted is-small" @click="nextDateRange()">
                            <span class="icon is-small">
                                <i class="fas fa-angle-right"></i>
                            </span>
                        </button>
                    </div>
                    <div class="box date-selector" v-if="showMenu">

                        <button class="button is-inverted is-dark has-text-grey is-small close-button"
                                @click="() => showMenu = false">
                            <span class="icon is-small">
                                <i class="fas fa-times"></i>
                            </span>
                        </button>

                        <label class="label is-small">Date Range</label>
                        <div class="field">
                            <div class="select is-fullwidth">
                                <select v-model="dateRange" @change="setDateRange();datesChanged()">
                                    <option value="">Custom</option>
                                    <optgroup label="Date Range">
                                        <option value="0">Today</option>
                                        <option value="7">7 Days</option>
                                        <option value="30">30 Days</option>
                                        <option value="60">60 Days</option>
                                        <option value="120">120 Days</option>
                                        <option value="ytd">Year to Date</option>
                                    </optgroup>
                                    <optgroup label="Date Range">
                                        <option value="week">Last Week</option>
                                        <option value="month">Last Month</option>
                                        <option value="year">Last Year</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>

                        <label class="label is-small">Dates</label>
                        <div class="field has-addons">
                            <div class="control is-expanded">
                                <type-date-picker
                                    size="normal"
                                    placeholder="From"
                                    icon="calendar-alt"
                                    format="MMM Do, YYYY"
                                    v-model="dates.from"
                                    @input="date => {datesChanged();dateRange = '';}"
                                    :maxdate="today"
                                >
                                </type-date-picker>
                            </div>
                            <div class="control">
                                <button class="button">-</button>
                            </div>
                            <div class="control is-expanded">
                                <type-date-picker
                                    size="normal"
                                    placeholder="To"
                                    icon="calendar-alt"
                                    format="MMM Do, YYYY"
                                    v-model="dates.to"
                                    @input="date => {datesChanged();dateRange = '';}"
                                    :mindate="dates.from"
                                    :maxdate="today"
                                >
                                </type-date-picker>
                            </div>
                        </div>

                        <label class="label is-small">Period</label>
                        <div class="buttons has-addons">
                            <button class="button is-expanded" :class="{'is-primary': period == 'hourly'}"
                                    @click="() => period = 'hourly' ">Hourly
                            </button>
                            <button class="button is-expanded" :class="{'is-primary': period == 'daily'}"
                                    @click="() => period = 'daily' ">Daily
                            </button>
                            <button class="button is-expanded" :class="{'is-primary': period == 'weekly'}"
                                    @click="() => period = 'weekly' ">Weekly
                            </button>
                        </div>

                        <hr>

                        <div class="has-text-centered">
                            <button class="button is-primary is-inverted" @click="updateChart()">Update Chart</button>
                        </div>

                    </div>
                </div>
            </div>

            <box-loader v-model="loading">
                <component
                    :is="chart"
                    :width="null"
                    :height="null"
                    :loading="loading"
                    :chartdata="chartdata"
                ></component>
                <slot name="charttable" v-bind:chartTable="chartTable" v-if="$scopedSlots.charttable"></slot>
            </box-loader>
        </div>
    </div>
</template>

<script>
import AutoCloseDirective from "@directives/AutoClose/AutoCloseDirective";

import Colors from "./Colors";

import BoxLoaderComponent from "@components/Loader/BoxLoaderComponent.vue";
import TypeDatePickerComponent from "@components/TypeDatePicker/TypeDatePickerComponent.vue";

import LineChart from "./Line.vue";
import DoughnutChart from "./Doughnut.vue";

import { axios, moment } from '@master';

export default {
    props: {
        defaults: Object,
        label: {
            type: String,
            default: null
        },
        labelsFormat: {
            type: String,
            default: null
        },
        chart: {
            type: String,
            default: null
        },
        endpoint: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            showMenu: false,
            today: moment(),
            loading: true,
            dateRange: null,
            period: null,
            dates: {
                from: null,
                to: null
            },
            chartdata: {},
            chartTable: null
        }
    },
    methods: {
        setDateRange() {
            if (this.dateRange == 'year') {
                this.dates.from = this.today.clone().subtract(1, 'year').startOf('year');
                this.dates.to = this.today.clone().subtract(1, 'year').endOf('year');
            } else if (this.dateRange == 'month') {
                this.dates.from = this.today.clone().subtract(1, 'month').startOf('month');
                this.dates.to = this.today.clone().subtract(1, 'month').endOf('month');
            } else if (this.dateRange == 'week') {
                this.dates.from = this.today.clone().subtract(1, 'week').startOf('week');
                this.dates.to = this.today.clone().subtract(1, 'week').endOf('week');
            } else if (this.dateRange == 'ytd') {
                this.dates.from = moment({year: this.today.year(), month: "00", day: "01"});
                this.dates.to = this.today.clone();
            } else {
                this.dates.from = this.today.clone().subtract(this.dateRange, 'days');
                this.dates.to = this.today.clone();
            }
        },
        autoClose() {
            this.showMenu = false;
        },
        updateChart() {
            this.showMenu = false;
            this.loadChart();
        },
        loadChart() {
            this.loading = true;

            // Clear the chart datasets & labels
            this.chartdata.datasets.length = 0;
            this.chartdata.labels.length = 0;

            axios.get(this.endpoint, {
                params: {
                    from: this.dates.from.format('YYYY-MM-DD'),
                    to: this.dates.to.format('YYYY-MM-DD'),
                    period: this.period
                }
            }).then(response => {

                if (response.data.tableData) {
                    this.chartTable = response.data.tableData;
                }

                let chartData = response.data.chartData;

                /**
                 * Set the chart labels
                 */
                for (let dataKey in chartData) {
                    for (let label in chartData[dataKey]) {
                        if (!this.chartdata.labels.includes(label)) {
                            this.chartdata.labels.push(label);
                        }
                    }
                }

                let dataset = 0;
                for (let dataKey in chartData) {
                    this.chartdata.datasets[dataset] = {
                        label: null,
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        pointBackgroundColor: null
                    };
                    let i = 0;
                    for (let point in chartData[dataKey]) {
                        this.chartdata.datasets[dataset].label = dataKey;
                        this.chartdata.datasets[dataset].data.push(chartData[dataKey][point]);

                        this.chartdata.datasets[dataset].backgroundColor.push(Colors.getColor(i));
                        this.chartdata.datasets[dataset].borderColor = 'white';
                        i++;
                    }

                    if (this.chart == 'line-chart') {
                        this.chartdata.datasets[dataset].pointBackgroundColor = Colors.getColor(dataset);
                        this.chartdata.datasets[dataset].borderColor = Colors.getColor(dataset);
                    }

                    dataset++;
                }

                this.loading = false;
            }).catch(error => {
                this.loading = false;
            })
        },
        datesChanged() {
            if (this.dates.from.isSame(this.dates.to)) {
                this.period = 'hourly';
            } else if (this.dates.from.diff(this.dates.to, 'days') <= -90) {
                this.period = 'weekly';
            } else {
                this.period = 'daily';
            }
        },
        prevDateRange() {
            let diff = this.dates.to.diff(this.dates.from, 'days');
            diff += 1;
            this.dates.from = this.dates.from.clone().subtract(diff, 'days');
            this.dates.to = this.dates.to.clone().subtract(diff, 'days');
            this.dateRange = "";
            this.updateChart();
        },
        nextDateRange() {
            let diff = this.dates.to.diff(this.dates.from, 'days');
            diff += 1;
            this.dates.from = this.dates.from.clone().add(diff, 'days');
            this.dates.to = this.dates.to.clone().add(diff, 'days');
            this.dateRange = "";
            this.updateChart();
        }
    },
    computed: {
        dateLabel() {
            let from = this.dates.from.format('MMM. Do, YYYY');
            let to = this.dates.to.format('MMM. Do, YYYY');

            if (from == to) {
                return from;
            } else {
                return `${from} - ${to}`;
            }

        }
    },
    mounted() {
        this.chartdata = {
            labels: [],
            datasets: []
        };

        if (this.defaults != null) {
            this.dateRange = (this.defaults.dateRange == null) ? "7" : this.defaults.dateRange;
            this.period = (this.defaults.period == null) ? "daily" : this.defaults.period;
        } else {
            this.dateRange = "7";
            this.period = "daily";
        }

        this.setDateRange();
        this.loadChart();
    },
    components: {
        'box-loader': BoxLoaderComponent,
        'type-date-picker': TypeDatePickerComponent,
        'line-chart': LineChart,
        'doughnut-chart': DoughnutChart
    },
    directives: {
        'auto-close': AutoCloseDirective,
    }
}
</script>

<style lang="scss" scoped>
.box {
    margin-bottom: 1.5rem;
}

.date-selector {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 1px;
    left: 50%;
    position: absolute;
    top: 38px;
    transform: translateX(-50%);
    width: 415px;
    z-index: 50;
}

.close-button {
    font-size: .85rem;
    position: absolute;
    right: .25rem;
    top: .25rem;
}
</style>
