<template>
    <div class="uploader">
        <div class="message is-small is-success" v-if="success != null">
            <div class="message-body">{{success}}</div>
        </div>
        <div class="message is-small is-danger" v-if="error != null">
            <div class="message-body">{{error}}</div>
        </div>
        <div class="logo" :disabled="disabled">
            <box-loader v-model="loading">
                <img :src="tmpImg" alt="">
            </box-loader>
        </div>
        <div class="screen" v-if="!disabled">
            <div class="icon is-large">
                <i class="fas fa-2x fa-upload"></i>
            </div>
            <div>Upload new logo.</div>
        </div>
        <input type="file" :disabled="disabled" @change="setLogo($event)" />
    </div>
</template>

<script>
import BoxLoaderComponent from '../Loader/BoxLoaderComponent.vue';
import { axios } from '@master';

export default {
    props: {
        endpoint: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            success: null,
            error: null,
            logo: null,
            tmpImg: null
        };
    },
    methods: {
        setLogo($event) {
            if($event != null && $event.target.files.length > 0) {
                this.logo = $event.target.files[0];
                this.tmpImg = URL.createObjectURL(this.logo);
                this.uploadLogo(this.logo);
            }
        },
        uploadLogo(logo) {
            this.loading = true;
            let file = new Blob([logo], { type: logo.type })
            let formData = new FormData();
            formData.append('logo', file, file.fileName);
            axios.post(this.endpoint, formData).then(response => {
                this.$emit('input', response.data);
                this.success = "Logo uploaded successfully!";
                this.error = null;
                setTimeout(() => {
                    this.success = null;
                }, 2500);
            }).catch(error => {
                this.success = null;
                this.error = error.data.message;
                setTimeout(() => {
                    this.success = null;
                }, 3500);
            }).then(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.tmpImg = this.value;
    },
    watch: {
        value(val) {
            this.tmpImg = this.value;
        }
    },
    components: {
        'box-loader': BoxLoaderComponent
    }
}
</script>

<style lang="scss" scoped>
.uploader {
    position: relative;
    .logo {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
        img {
            max-height: 100%;
        }
        &:hover {
            filter: blur(2px);
            .screen {
                opacity: 1;
            }
        }
    }
    .loading-wrapper {
        position: initial;
    }
    input[type="file"] {
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }
    .screen {
        align-items: center;
        background: rgba(255, 255, 255, 0.25);
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    .message {
        bottom: .5rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        left: 0.5rem;
        margin: 0;
        position: absolute;
        width: calc(100% - 1rem);
        z-index: 50;
    }
    &:hover {
        .logo:not([disabled]) {
            cursor: pointer;
            filter: blur(2px);
        }
        .screen {
            opacity: 1;
        }
    }
}
</style>
