<template>
    <div class="is-inline">
        <modal v-model="show" @input="closeModal()">
            <template slot="header">
                <span class="is-capitalized">{{ method }} Vendor</span>
            </template>
            <div class="field">
                <label class="label is-small">Vendor Code</label>
                <input
                    type="text"
                    class="input"
                    v-model="vendorCode"
                    :disabled="processing || deleting"
                    />
            </div>
            <div class="field">
                <label class="label is-small is-capitalized">Identifier</label>
                <input
                    type="text"
                    class="input"
                    v-model="identifier"
                    :disabled="processing || deleting"
                    />
                <p class="help has-text-grey">Carrier Code/Office ID</p>
            </div>
            <div class="field">
                <label class="label is-small">Category</label>
                <div class="select is-fullwidth is-size-6">
                    <select v-model="category">
                        <option value="carrier">Carrier</option>
                        <option value="supplier">Supplier</option>
                    </select>
                </div>
            </div>
            <div class="field">
                <label class="label is-small">Description</label>
                <input
                    type="text"
                    class="input"
                    v-model="description"
                    :disabled="processing || deleting"
                    />
            </div>
            <div class="columns">
                <div class="column is-narrow" v-if="method == 'edit'">
                    <button
                        class="button is-primary is-inverted is-capitalized"
                        :class="{'is-loading': deleting, 'is-outlined': deleting}"
                        @click="deleteVendor()"
                        :disabled="processing || deleting"
                        >
                        Delete Vendor
                    </button>
                </div>
                <div class="column"></div>
                <div class="column is-narrow">
                    <template v-if="method == 'add'">
                        <button
                            class="button is-primary is-capitalized"
                            :class="{'is-loading': processing}"
                            @click="createVendor()"
                            :disabled="processing"
                            >
                            Add Vendor
                        </button>
                    </template>
                    <template v-else-if="method == 'edit'">
                        <button
                            class="button is-primary is-capitalized"
                            :class="{'is-loading': processing}"
                            @click="updateVendor()"
                            :disabled="processing || deleting"
                            >
                            Update Vendor
                        </button>
                    </template>
                </div>
            </div>
        </modal>
        <span @click="openModal()">
            <slot name="trigger"></slot>
        </span>
    </div>
</template>

<script>
import Modal from '@components/Modal/ModalComponent.vue';
import { axios } from '@master';

export default {
    props: {
        method: {
            type: String,
            default: 'add'
        },
        vendor: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            processing: false,
            deleting: false,
            show: false,
            id: null,
            vendorCode: null,
            identifier: null,
            category: null,
            description: null,
        }
    },
    methods: {
        openModal() {
            this.show = true;
        },
        closeModal() {
            this.show = false;
        },
        async createVendor() {
            this.processing = true;
            try {
                await axios.post(`/admin/accounting/vendor_list/create`, {
                    vendor: {
                        vendorCode: this.vendorCode,
                        identifier: this.identifier,
                        category: this.category,
                        description: this.description
                    }
                });
                this.show = false;
                this.clear();
                this.$emit('created');
            } catch ({data}) {
                this.$emit('error', data);
            }
            this.processing = false;
        },
        async updateVendor() {
            this.processing = true;
            try {
                await axios.put(`/admin/accounting/vendor_list/update`, {
                    id: this.id,
                    vendor: {
                        vendorCode: this.vendorCode,
                        identifier: this.identifier,
                        category: this.category,
                        description: this.description
                    }
                });
                this.show = false;
                this.clear();
                this.$emit('updated');
            } catch ({data}) {
                this.$emit('error', data);
            }
            this.processing = false;
        },
        async deleteVendor() {
            this.deleting = true;
            try {
                await axios.delete(`/admin/accounting/vendor_list/delete`, {
                    params: {
                        id: this.id,
                    }
                });
                this.show = false;
                this.clear();
                this.$emit('deleted');
            } catch ({data}) {
                this.$emit('error', data);
            }
            this.deleting = false;
        },
        clear() {
            this.id = null;
            this.vendorCode = '';
            this.identifier = '';
            this.category = '';
            this.description = '';
        },
        setVendor(vendor) {
            this.id = vendor.id;
            this.vendorCode = vendor.vendorCode;
            this.identifier = vendor.identifier;
            this.category = vendor.category;
            this.description = vendor.description;
        }
    },
    watch: {
        vendor(vendor) {
            this.setVendor(vendor);
        }
    },
    mounted() {
        if(this.vendor != null) {
            this.setVendor(this.vendor);
        }
    },
    components: {
        Modal
    }
}
</script>
