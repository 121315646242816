export default {
    methods: {
        gaPageView(url = null, title = null) {
            const properties = new Map();
            if(url != null) {
                properties.set('page_path', url);
            }
            if(title != null) {
                properties.set('page_title', `${title} :: Flight Sugar`);
            }

            if(properties.size > 0) {
                gtag('event', 'page_view', Object.fromEntries(properties));
            } else {
                gtag('event', 'page_view');
            }
        },
        gaEvent(category, action, label, data=null) {
            let params = {};
            params['event_category'] = category;
            params['event_label'] = label;
            if(data != null) {
                for(let param in data) {
                    params[param] = data[param];
                }
            }
            gtag('event', action, params);
        }
    },
}