<template>
    <div class="field has-addons">
        <div class="control is-expanded">
            <slot></slot>
        </div>
        <div class="control">
            <button
                class="button"
                :class="[
                    `is-${size}`,
                    {
                        'is-info': !isUpdating,
                        'is-info is-loading': isUpdating,
                        'is-success': isDone && isSuccess,
                        'is-danger': isDone && isError
                    }
                ]"
                @click="update()"
                :disabled="isUpdating || !changed">
                <span class="icon">
                    <i class="fas fa-save" v-if="!isDone"></i>
                    <i class="fas fa-check" v-else-if="isDone && isSuccess"></i>
                    <i class="fas fa-times" v-else-if="isDone && isError"></i>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        callback: {
            type: Function,
            validator: (prop) => true
        },
        size: String
    },
    data() {
        return {
            element: null,
            changed: false,
            isUpdating: false,
            isDone: false,
            isSuccess: false,
            isError: false
        };
    },
    methods: {
        async update() {
            this.isUpdating =  true;
            this.isDone = false;

            try {
                await this.callback();
                this.changed = false;
                this.isUpdating = false;
                this.isDone = true;
                this.isError = false;
                this.isSuccess = true;
                setTimeout(() => {
                    this.isDone = false;
                }, 3000);
            } catch (error) {
                console.log('inline', error);
                this.changed = false;
                this.isUpdating = false;
                this.isDone = true;
                this.isError = true;
                this.isSuccess = false;
                setTimeout(() => {
                    this.isDone = false;
                }, 3000);
            }
        },
        listenToInput() {
            this.element = this.$slots.default[0].elm;
            if(this.element.nodeName != 'INPUT') {
                this.element = this.element.getElementsByTagName('input')[0];
            }
            this.element.addEventListener('input', () => {
                this.changed = true;
            });
        }
    },
    mounted() {
        this.listenToInput();
    },
    beforeDestroy() {
        this.element.removeEventListener('input');
    }
}
</script>
