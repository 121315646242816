import Vue from 'vue';
import VeeValidate from "vee-validate";
import axios from 'axios';
import moment from 'moment';

export const isset = (variable) => {
    if(typeof variable !== 'undefined' && variable !== null) {
        return true;
    }
    return false;
}

const errorVue = new Vue();

if (!HTMLElement.prototype.scrollTo) {
    HTMLElement.prototype.scrollTo = function (obj) {
        if(isset(obj.top)) {
            this.scrollTop = obj.top;
        }
        if(isset(obj.left)) {
            this.scrollLeft = obj.left;
        }
    }
}

export const eventBus = new Vue();

import * as Vuex from 'vuex';
Vue.use(Vuex);

import swal from '@plugins/swal/index';
Vue.use(swal);

import properties from '@plugins/properties/index';
Vue.use(properties);

const messages = {
    email: (field) => `You must provide a valid ${field}.`,
    confirmed: (field, [match]) => `Your ${field} and your ${match} do not match.`,
    min_value: (field, [min]) => `You must select ${min} or more ${field}.`,
    required: (field) => `You must provide a valid ${field}.`
};

const locale = {
    name: 'en',
    messages,
    attributes: {}
};

VeeValidate.Validator.localize({'en': locale });

let instance = new VeeValidate.Validator();
instance.extend('phone', {
    getMessage: field => "You must provide a valid phone number.",
    validate: value => {
        let regex = new RegExp(/^[0-9\+\-\s\(\)]+$/);
        if(regex.test(value)) {
            return true;
        } else {
            return false;
        }
    }
});
instance.extend('adt_pax', {
    getMessage: field => "You must have at least one adult traveler.",
    validate: value => {
        if(value && value.adult > 0) {
            return true;
        }
        return false;
    }
});
instance.extend('total_pax', {
    getMessage: field => "You must have at least one traveler.",
    validate: value => {
        if(value && value.total > 0) {
            return true;
        }
        return false;
    }
});
instance.extend('adt_inf_match', {
    getMessage: field => "You must have the same number of adults as infants.",
    validate: value => {
        if(value && (value.infant == 0 || value.adult >= value.infant)) {
            return true;
        }
        return false;
    }
});
instance.extend('notEmail', {
    getMessage: field => "You must not provide a full email address.",
    validate: value => {
        if(value.match(/@/) == null) {
            return true;
        } else {
            return false;
        }
    }
})

Vue.use(VeeValidate);

Vue.filter('hide', function(value) {
    return '';
});

Vue.filter('toCurrency', function(value, showCents=true) {
    if(value == null || value == "" || isNaN(value)) {
        value = 0;
    }
    if(typeof value != "number" && typeof value != "string") {
        value = 0;
    }
    if(typeof value == "string") {
        value = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    }
    let sign = '';
    if(value < 0) {
        sign = '-';
        value = value * -1;
    }
    if(showCents == false) {
        value = Math.floor(value).toFixed(0);
    } else {
        value = value.toFixed(2);
    }
    value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${sign}$${value}`;
});

Vue.filter('toDecimal', function(value) {
    value = value % 1;
    value = value.toFixed(2);
    return (""+value).substr(1);
});

Vue.filter('toPhone', function(value) {
    if(value == null) {
        return "";
    }
    value = value.replace(/\D/g, '');
    let match;
    if(value.length == 7) {
        match = value.match(/^(\d{3})(\d{4})$/)
    } else {
        match = value.match(/^(\d{3})(\d{3})(\d{4})$/)
    }
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
});

Vue.filter('formatDate', function(value, format = "MMM. Do") {
    if(value == null || value == "") {
        return null;
    } else {
        return moment(value, 'Y-M-D').format(format);
    }
});

axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};
axios.interceptors.response.use(response => response, error => {
    if(error.response) {
        if(error.response.status === 419 || error.response.status === 401) {
            if(error.response.config.url !== '/air') {
                window.location.reload();
            }
        } else if(error.response.status === 403) {
            window.location.href = '/email/verify';
            return;
        }
        return Promise.reject(error.response);
    } else {
        if(axios.isCancel(error)) {
        } else {
            errorVue.swalError(error);
        }
    }
});

import login from '@plugins/login/index';
Vue.use(login);

import title from '@mixins/title/index';
Vue.mixin(title);

import analytics from '@mixins/analytics/index';
Vue.mixin(analytics);

import alerts from "@plugins/alerts/index.js";
Vue.use(alerts);

export {axios, Vue, moment};
