<template>
    <div class="wrapper" v-auto-close="autoClose">
        <div class="field is-marginless">
            <div class="label" v-if="label">{{label}}</div>
            <div class="control" :class="[icon != null ? 'has-icons-left' : '', searching ? 'is-loading' : '', `is-${size}` ]">
                <input
                    class="input"
                    type="text"
                    :value="newValue"
                    ref="input"
                    :class="[`is-${size}`, `is-${inputStyle}`]"
                    :placeholder="placeholder"
                    autocomplete="off"
                    @keydown="handleArrowKeys"
                    :tabindex="tabindex"
                    :autofocus="autofocus"
                    @input="inputChange"
                    :disabled="disabled"
                    >
                <span class="icon is-left" v-if="icon != null">
                    <i class="fas" :class="`fa-${icon}`"></i>
                </span>
            </div>
        </div>
        <div class="results has-background-white" :class="resultsPosition" v-if="showResults">
            <div class="result" v-for="(result, index) in results" :style="{'background-color': selectedItem == index ? '#F5F5F5' : ''}" :key="index" @click="setResult(result, true); clearResults();">
                <slot name="result" v-bind:result="result"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import AutoCloseDirective from "@directives/AutoClose/AutoCloseDirective";
import { axios } from '@master';

export default {
    props: {
        icon: {
            type: String,
            default: null
        },
        placeholder: String,
        size: {
            default: 'normal',
            type: String
        },
        endpoint: String,
        label : {
            type: String,
            default: null
        },
        inputStyle : {
            type: String,
            default: ''
        },
        value: [String, Object, Array],
        display: {
            type: String|Array,
            default: null
        },
        resultsPosition: {
            type: String,
            default: 'left'
        },
        tabindex: {
            type: Number,
            default: null
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        setOnBlur: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            newValue: "",
            results: [],
            debounceTimeId: null,
            showResults: false,
            searching: false,
            token: null,
            selectedItem: 0
        }
    },
    methods: {
        doSearch(event) {
            if(this.token != null) {
                this.token.cancel();
                this.token = null;
            }
            if((this.newValue == null || this.newValue.length <= 0)) {
                this.searching = false;
                this.showResults = false;
                this.newValue = null;
                this.results = [];
                this.$emit('input', this.newValue);
                return;
            }

            let encodedValue = encodeURIComponent(this.newValue);

            this.token = axios.CancelToken.source();
            this.searching = true;
            axios.get(this.endpoint.replace("{term}", encodedValue), { cancelToken: this.token.token } ).then((results) => {
                if(results && results.data.length > 0) {
                    this.results = results.data;
                    this.showResults = true;
                } else {
                    this.results.length = 0;
                    this.showResults = false;
                }
                this.searching = false;
            }).catch(error => {
                this.showResults = false;
            });
        },
        setResult(result, focus) {
            if(this.display == 'result') {
                this.newValue = result;
            } else if(typeof this.display == 'object' && this.display != null) {
                if(result != null) {
                    let display = [];
                    for(let name of this.display) {
                        display.push(result[name]);
                    }
                    this.newValue = display.join(" ");
                }
            } else if(this.display != null) {
                if(result == null) {
                    this.newValue = null;
                    return;
                }
                let val = this.resolve(this.display, result);
                if(val == null || val == "") {
                    this.newValue = null;
                }
                if(typeof val == "object") {
                    this.newValue = Object.values(val).join(" ");
                } else if(typeof val == "array") {
                    this.newValue = val.join(" ");
                } else {
                    this.newValue = val;
                }
            }
            this.$emit('input', result);
            if(focus) {
                setTimeout(() => {
                    this.$refs.input.focus()
                }, 1);
            }
        },
        clearResults() {
            this.results.length = 0;
            this.showResults = false;
        },
        resolve(path, obj) {
            return path.split('.').reduce((prev, curr) => {return prev ? prev[curr] : null }, obj || self)
        },
        autoClose() {
            this.results.length = 0;
            this.showResults = false;
        },
        handleArrowKeys(event) {
            if(event.keyCode == 38) {// Up
                event.preventDefault();
                this.selectedItem = this.selectedItem <= 0 ? 0 : this.selectedItem - 1;
            } else if(event.keyCode == 40) {// Down
                event.preventDefault();
                this.selectedItem = this.selectedItem <= this.results.length - 2 ? this.selectedItem + 1 : this.results.length - 1 ;
            } else if(event.keyCode == 13) {// Enter
                event.preventDefault();
                this.setResult(this.results[this.selectedItem]);
                this.clearResults();
            } else if(event.keyCode == 9) {
                this.handleBlur();
            }
        },
        handleBlur() {
            if(this.setOnBlur === true && this.results.length > 0) {
                this.setResult(this.results[this.selectedItem]);
                this.clearResults();
            }
        },
        inputChange($event) {
            if(this.newValue != $event.target.value) {
                if(this.token != null) {
                    this.token.cancel();
                    this.token = null;
                }
                this.newValue = $event.target.value;
                clearTimeout(this.debounceTimeId);
                this.debounceTimeId = setTimeout(() => {
                    this.doSearch();
                }, 300);
            }
        }
    },
    mounted() {
        this.setResult(this.value);
    },
    watch: {
        value(value) {
            this.setResult(value);
        }
    },
    directives: {
        'auto-close': AutoCloseDirective
    }
}
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/all";
.wrapper {
    position: relative;
    .results {
        border: solid 1px rgb(219,219,219);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        max-height: 370px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        top: calc(100% - 1px);
        white-space: nowrap;
        z-index: 55;
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        .result {
            border-bottom: solid 1px hsl(0, 0%, 96%);
            transition: background-color 150ms linear;
            &:hover {
                background-color: hsl(0, 0%, 96%);
                cursor: pointer;
            }
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}
.is-inverted {
    background-color: transparent;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    box-shadow: none;
}
.is-inverted:focus, .is-inverted:active {
    box-shadow: none;
}
</style>
<style>
.highlight {
    text-decoration: underline!important;
    font-weight: 700!important;
}
</style>
