<template>
    <div @click="copy()" class="copy">
        <tooltip position="bottom" :underline="false" v-model="copied" style="max-width: 100%">
            <template slot="tooltip">
                <span class="icon is-small"><i class="fas fa-check fa-sm"></i></span>
                <span>Copied</span>
            </template>
            <template slot="content">
                <slot />
                <span class="icon copy-icon is-small has-text-grey" :style="{'display': value == null ? '' : 'none'}"><i class="fas fa-copy fa-sm"></i></span>
            </template>
        </tooltip>
        <input type="" class="input" readonly v-model="content">
    </div>
</template>

<script>
import TooltipComponent from '../Tooltip/TooltipComponent.vue';
export default {
    props: {
        value: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            input: null,
            content: null,
            copied: false
        }
    },
    methods: {
        copy() {
            if(!this.input != null) {
                this.input.focus();
                this.input.select();
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                this.copied = true;
                setTimeout(() => {
                    this.copied = false;
                }, 2000);
            }
        }
    },
    mounted() {
        this.input = this.$el.getElementsByTagName('input')[0];

        if(this.value != null) {
            this.content = this.value;
        } else if(this.$slots.default && this.$slots.default[0] && this.$slots.default[0].text) {
            this.content = this.$slots.default[0].text;
        }

    },
    watch: {
        value(value) {
            this.content = value;
        }
    },
    components: {
        'tooltip': TooltipComponent
    }
}
</script>

<style lang="scss" scoped>
    .copy {
        cursor: pointer;
        display: inline;
        position: relative;
    }
    .icon {
        left: 100%;
        font-size: 14px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .copy:hover .icon.copy-icon {
        opacity: 1;
    }
    .copy .icon.copy-icon {
        opacity: 0;
        transition: 100ms opacity linear;
    }
    input.input, input.input:hover {
        background-color: transparent!important;
        border: none!important;
        box-shadow: none!important;
        cursor: pointer;
        font-size: inherit!important;
        height: 1px;
        left: 0;
        line-height: inherit!important;
        opacity: 0;
        padding: 0!important;
        position: absolute;
        top: 0;
        width: 1px;
    }
</style>
