<template>
    <div v-if="times && times.min != null && times.max != null">
        <div class="is-size-7 has-text-grey is-overflow">{{title}} <span class="has-text-weight-semibold">{{ minValue }} - {{ maxValue }}</span></div>
        <div class="wrapper">
            <slider
                :buttons="{min: values.min, max: values.max}"
                :range="{min: range.min, max: range.max}"
                :tween="['min','max']"
                @drag="(times) => updateTimes(times, 'drag')"
                @drop="(times) => updateTimes(times, 'drop')"
            >
            </slider>
        </div>
    </div>
</template>

<script>
import SliderComponent from "../Slider/SliderComponent.vue";

export default {
    props: {
        times: {
            type: Object
        },
        limits: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ""
        },
        format: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            values: {},
            range: {}
        }
    },
    methods: {
        formatTime(time, format) {
            let min = (time % 1 > .3 && time % 1 < .7) ? '30' : '00';
            if(format == '24hour') {
                let hour = Math.floor(time);
                return `${hour}:${min}`;
            } else if(format == '12hour') {
                let hour = Math.floor(time % 12);
                hour = (hour == 0) ? 12 : hour;
                let ampm = time < 12 ? 'AM' : 'PM';
                return `${hour}:${min}${ampm}`;
            }
        },
        updateTimes(times, event = null) {
            this.values.min = Math.round(times.min);
            this.values.max = Math.round(times.max);
            if(event == 'drop') {
                this.$emit('selected', this.values);
            }
        }
    },
    mounted() {
        this.values = Object.assign({}, this.times);
        if(this.limits == null) {
            this.range = Object.assign({}, this.times);
        } else {
            this.range = Object.assign({}, this.limits);
        }
    },
    computed: {
        minValue() {
            if(this.format == null) {
                return `${this.values.min} ${this.label}`;
            } else {
                return `${this.formatTime(this.values.min, this.format)} ${this.label}`;
            }
        },
        maxValue() {
            if(this.format == null) {
                return `${this.values.max} ${this.label}`;
            } else {
                return `${this.formatTime(this.values.max, this.format)} ${this.label}`;
            }
        }
    },
    watch: {
        times: {
            handler: function(times) {
                this.updateTimes(times);
            },
            deep: true
        }
    },
    components: {
        'slider': SliderComponent
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    margin-bottom: 16px;
    position: relative;
}
</style>
