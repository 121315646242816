<template>
    <div>
        <div class="modal is-active" v-for="(alert, alertIndex) in alerts" :key="alertIndex">
            <div class="modal-background"></div>
            <div class="modal-content has-text-centered">
                <div class="box">

                    <!-- Error -->
                    <div class="has-text-danger" v-if="alert.type == 'error'">
                        <span class="icon is-large">
                            <i class="fas fa-times-circle fa-3x"></i>
                        </span>
                    </div>

                    <!-- Warning -->
                    <div class="has-text-warning" v-if="alert.type == 'warning'">
                        <span class="icon is-large">
                            <i class="fas fa-exclamation-circle fa-3x"></i>
                        </span>
                    </div>

                    <!-- Success -->
                    <div class="has-text-success" v-if="alert.type == 'success'">
                        <span class="icon is-large">
                            <i class="fas fa-check-circle fa-3x"></i>
                        </span>
                    </div>

                    <!-- Confirm -->
                    <div class="has-text-info" v-if="alert.type == 'confirm'">
                        <span class="icon is-large">
                            <i class="fas fa-question-circle fa-3x"></i>
                        </span>
                    </div>

                    <h3 class="is-size-4 has-text-weight-semibold header">{{alert.title}}</h3>

                    <p class="content" v-html="alert.content"></p>

                    <div class="buttons is-centered" v-if="alert.buttons != null && alert.buttons.length > 0">
                        <button class="button" v-for="(button, buttonIndex) in alert.buttons" :key="buttonIndex" :class="button.modifiers" @click.prevent="handle(alertIndex, button)">{{button.value}}</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            alerts: []
        }
    },
    methods: {
        handle(index, button) {
            if(typeof button.handler == 'function') {
                button.handler()
            }
            if(button.close == true) {
                this.close(index);
            }
        },
        close(index) {
            let alert = this.alerts[index];
            if(alert.callback && alert.callback != null) {
                (alert.callback)();
            }
            this.alerts.splice(index, 1);
        }
    },
    mounted() {
        this.$root.$on('alert', (alert) => {
            this.alerts.push(alert);
        });
    }
}
</script>

<style lang="scss" scoped>
.header {
    margin-top: 16px;
}
.content {
    margin: 16px 0 32px;
}
</style>
