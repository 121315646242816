<template>
    <box-loading v-model="sending">
        <form @submit.prevent="submitForm()">
            <div class="field">
                <label class="label is-small has-text-grey is-required">Name:</label>
                <div class="control has-icons-left">
                    <input
                        type="text"
                        class="input"
                        v-model="name"
                        v-validate.disable="'required'"
                        name="name"
                        >
                    <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                    </span>
                </div>
                <p class="help is-danger" v-show="errors.has('name')">{{ errors.first('name') }}</p>
            </div>
            <div class="field">
                <label class="label is-small has-text-grey is-required">Email:</label>
                <div class="control has-icons-left">
                    <input
                        type="text"
                        class="input"
                        v-model="email"
                        v-validate.disable="'required|email'"
                        name="email"
                        >
                    <span class="icon is-small is-left">
                        <i class="fas fa-envelope"></i>
                    </span>
                </div>
                <p class="help is-danger" v-show="errors.has('email')">{{ errors.first('email') }}</p>
            </div>
            <div class="field">
                <label class="label is-small has-text-grey">Phone:</label>
                <div class="control has-icons-left">
                    <input
                        type="text"
                        class="input"
                        v-model="phone"
                        >
                    <span class="icon is-small is-left">
                        <i class="fas fa-phone"></i>
                    </span>
                </div>
            </div>
            <div class="field">
                <label class="label is-small has-text-grey is-required">Subject:</label>
                <div class="control has-icons-left">
                    <div class="select is-fullwidth">
                        <select
                            v-model="subject"
                            v-validate.disable="'required'"
                            name="subject"
                            >
                                <option value="Question">Question</option>
                                <option value="Comment/Suggestion">Comment/Suggestion</option>
                                <option value="Problem/Error">Problem/Error</option>
                                <option value="Group Air Request">Group Air Request</option>
                                <option value="Other">Other</option>
                        </select>
                        <span class="icon is-small is-left">
                            <i class="fas fa-edit"></i>
                        </span>
                    </div>
                    <p class="help is-danger" v-show="errors.has('subject')">{{ errors.first('subject') }}</p>
                </div>
            </div>
            <div class="field">
                <label class="label is-small has-text-grey">Booking ID:</label>
                <div class="control has-icons-left">
                    <input
                        type="text"
                        class="input"
                        v-model="bookingId"
                        >
                    <span class="icon is-small is-left">
                        <i class="fas fa-atlas"></i>
                    </span>
                </div>
            </div>
            <div class="field">
                <label class="label is-small has-text-grey is-required">Message:</label>
                <textarea
                    class="textarea"
                    v-model="message"
                    v-validate.disable="'required'"
                    name="message"
                    >
                </textarea>
                <p class="help is-danger" v-show="errors.has('message')">{{ errors.first('message') }}</p>
            </div>
            <div class="field is-clearfix">
                <recaptcha v-model="recaptcha" :sitekey="this.sitekey"></recaptcha>
            </div>
            <div class="buttons is-right">
                <button class="button is-primary">Send Message</button>
            </div>
        </form>

    </box-loading>
</template>

<script>
import BoxLoaderComponent from "@components/Loader/BoxLoaderComponent.vue";
import CheckboxComponent from "@components/Checkbox/CheckboxComponent.vue";
import RecaptchaComponent from '@components/Recaptcha/RecaptchaComponent.vue';
import { axios } from '@master';

export default {
    props: {
        sitekey: {
            type: String
        },
        model: ''
    },
    data() {
        return {
            sending: false,
            name: "",
            email: "",
            phone: "",
            subject: "Question",
            bookingId: '',
            message: "",
            recaptcha: ''
        }
    },
    methods: {
        async submitForm() {
            const valid = await this.$validator.validate();
            if(!valid) {
                return;
            }

            this.sending = true;

            try {
                const payload = {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                    subject: this.subject,
                    bookingId: this.bookingId,
                    message: this.message,
                    captcha: this.recaptcha
                };

                const response = (await axios.post('/contact/submit', payload)).data;

                this.name = "";
                this.email = "";
                this.phone = "";
                this.subject = "Question";
                this.bookingId = '';
                this.message = "";
                this.recaptcha = '';
                this.sending = false;

                this.$emit('success')

                this.swalSuccess("Your message has been sent!");
                this.gaPageView('/contact/success', 'Contact Submission Successful');
            } catch (e) {
                this.sending = false;
                this.swalError(error.data.message);
            }
        }
    },
    mounted() {
        if(this.model) {
            for(let key of Object.keys(this.model)) {
                this[key] = this.model[key];
            }
        }
    },
    components: {
        'box-loading': BoxLoaderComponent,
        'checkbox': CheckboxComponent,
        'recaptcha': RecaptchaComponent
    }
}
</script>
