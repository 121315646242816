<template>
    <div class="is-inline">
        <modal v-model="show" @input="clear()">
            <template #header>
                <slot name="header">
                    Update Field
                </slot>
            </template>
            <form @submit.prevent="updateField()">
                <div class="field">
                    <label class="label is-size-7-half" v-if="$slots.label">
                        <slot name="label"></slot>
                    </label>
                    <input
                        type="text"
                        class="input"
                        :class="{'is-loading': saving}"
                        v-model="value"
                        :disabled="saving"
                        >
                </div>
                <div class="buttons is-right">
                    <button
                        class="button is-primary mx-0"
                        :class="{'is-loading': saving}"
                        :disabled="saving"
                        >
                        <slot name="button">
                            Update
                        </slot>
                    </button>
                </div>
            </form>
        </modal>
        <span @click="openModal()">
            <slot name="trigger"></slot>
        </span>
    </div>
</template>

<script>
import Modal from '@components/Modal/ModalComponent.vue';
import { axios } from '@master';

export default {
    props: {
        field: {
            type: String,
            required: true
        },
        businessId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            show: false,
            saving: false,
            value: null
        }
    },
    methods: {
        openModal() {
            this.show = true;
        },
        clear() {
            this.value = null;
        },
        async updateField() {
            this.saving = true;
            try {
                const payload = {
                    field: this.field,
                    value: this.value
                };
                await axios.put(`/admin/businesses/${this.businessId}/update/field`, payload);
                this.show = false;
                this.clear();
                this.$emit('updated');
            } catch ({data}) {
                this.$emit('error', data);
            }
            this.saving = false;
        }
    },
    components: {
        Modal
    }
}
</script>
