<template>
    <div>
        <div class="tabs" :class="[...modifiers, {'vertical': vertical}]">
            <ul>
                <li v-for="(tab, index) in tabList" :key="index" :class="{'is-active': tab.isActive}">
                    <a @click="changeTab(tab)">
                        <tab-label :tab="tab"></tab-label>
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-panels">
            <slot />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String|Number,
                default: ''
            },
            modifiers: {
                type: Array,
                default: function () { return []; }
            },
            vertical: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                activeTab: null,
                tabList: []
            }
        },
        methods: {
            changeTab(tab) {
                this.tabList.forEach(tab => tab.isActive = false);
                tab.isActive = true;
                this.$emit('input', tab.name);
            },
            setTab(value) {
                let tabId = this.tabList.map(tab => {return tab.name}).indexOf(value);
                if(tabId >= 0) {
                    this.changeTab(this.tabList[tabId]);
                } else {
                    this.changeTab(this.tabList[0]);
                }
            }
        },
        watch: {
            value(val) { this.setTab(val); },
            tabList(list) { this.setTab(this.value); }
        },
        components: {
            'tab-label': {
                props: {
                    tab: Object
                },
                methods: {
                    refresh() {
                        this.$forceUpdate()
                    }
                },
                created() {
                    this.tab.$on('hook:updated', this.refresh)
                },
                beforeDestroy() {
                    this.tab.$off('hook:updated', this.refresh)
                },
                render(createElement) {
                    return createElement('span', {}, this.tab.$slots['label']);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.tabs.vertical {
    width: 160px;
}

.tabs.vertical ul {
    -webkit-flex-direction: column;
    flex-direction: column;
}

.tabs.vertical li+li {
    margin-left: 0;
}
</style>
