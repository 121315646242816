<template>
    <div class="wrapper" v-auto-close="autoClose">
        <div class="field is-marginless">
            <div class="control has-icons-left has-icons-right">
                <input class="input is-normal" type="text" :value="label" readonly placeholder="How Many Travelers?" :tabindex="tabindex" :autofocus="autofocus" @mousedown="togglePicker" @focus="togglePicker" @blur="handleBlur">
                <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                </span>
                <span class="icon is-small is-right">
                    <i class="fas fa-angle-down"></i>
                </span>
            </div>
        </div>
        <div class="pax-window" v-show="showing">
            <p class="is-size-5">Travelers</p>
            <div class="field is-horizontal">
                <div class="field-label is-small">
                    <label class="label">Adult</label>
                    <span class="is-size-7">(age 12+)</span>
                </div>
                <div class="field-body">
                    <number-input
                        :value="paxAdult"
                        :min="0"
                        :max="9"
                        @change="setAdult($event)"
                        :maxdisabled="total >= maxPax"
                        :tabindex="tabindex + 1"
                        >
                    </number-input>
                </div>
            </div>

            <div class="field is-horizontal">
                <div class="field-label is-small">
                    <label class="label">Child</label>
                    <span class="is-size-7">(age 2-11)</span>
                </div>
                <div class="field-body">
                    <number-input
                        :value="paxChild"
                        :min="0"
                        :max="9"
                        @change="setChild($event)"
                        :maxdisabled="total >= maxPax"
                        :tabindex="tabindex + 1"
                        >
                    </number-input>
                </div>
            </div>

            <div class="field is-horizontal">
                <div class="field-label is-small">
                    <label class="label">Infant</label>
                    <span class="is-size-7">(on lap)</span>
                </div>
                <div class="field-body">
                    <number-input
                        :value="paxInfant"
                        :min="0"
                        :max="9"
                        @change="setInfant($event)"
                        :maxdisabled="total >= maxPax"
                        :tabindex="tabindex + 1"
                        >
                    </number-input>
                </div>
            </div>

            <hr>

            <p class="is-size-5">Cabin</p>
            <div class="field">
                <div class="select is-fullwidth">
                    <select v-model="paxCabin" @change="() => {setLabel(); $emit('cabin', this.paxCabin);}" :tabindex="tabindex + 1">
                        <option v-for="(cabin, value) in cabins" :key="value" v-bind:value="value">{{cabin}}</option>
                    </select>
                </div>
            </div>

            <button class="button is-fullwidth is-primary has-text-weight-bold" @click="togglePicker" type="button" :tabindex="tabindex + 1" @blur="showing = false">SELECT</button>
            <hr>
            <p class="is-size-7 has-text-weight-semibold has-text-grey-dark has-text-centered">
                10 or more travelers?<br />
                <a href="/resources/flymygroup" class="has-text-primary">Request Group Space</a>
            </p>
        </div>
    </div>
</template>

<script>
import NumberInput from "../NumberInput/NumberInputComponent.vue";
import AutoCloseDirective from "@directives/AutoClose/AutoCloseDirective";

export default {
    $_veeValidate: {
        value() {
            return this.paxAdult + this.paxChild + this.paxInfant;
        }
    },
    props: {
        adult: {
            type: Number,
            default: 0
        },
        child: {
            type: Number,
            default: 0
        },
        infant: {
            type: Number,
            default: 0
        },
        cabin: {
            type: String,
            default: 'economy'
        },
        tabindex: {
            type: Number,
            default: null
        },
        autofocus: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            paxCabin: '',
            paxAdult: 0,
            paxChild: 0,
            paxInfant: 0,
            total: 0,
            maxPax: 9,
            label: [],
            showing: false,
            cabins: {
                'economy': 'Economy',
                'premium': 'Premium Economy',
                'business': 'Business Class',
                'first': 'First Class'
            }
        }
    },
    methods: {
        togglePicker(event) {
            if(event.type == 'mousedown' || event.type == 'click') {
                this.showing = !this.showing;
            } else if(event.type == 'focus') {
                this.showing = true;
            } else if(event.type == 'blur') {
                this.showing = false;
            }
        },
        setAdult(value) {
            this.paxAdult = value;
            this.setTotal();
            this.setLabel();
            this.$emit('adult', this.paxAdult);
        },
        setChild(value) {
            this.paxChild = value;
            this.setTotal();
            this.setLabel();
            this.$emit('child', this.paxChild);
        },
        setInfant(value) {
            this.paxInfant = value;
            this.setTotal();
            this.setLabel();
            this.$emit('infant', this.paxInfant);
        },
        setTotal() {
            this.total = this.paxAdult + this.paxChild + this.paxInfant;
            this.$emit('input', {
                'total': this.total,
                'adult': this.paxAdult,
                'child': this.paxChild,
                'infant': this.paxInfant
            });
        },
        setLabel() {
            let cabin = this.cabins[this.paxCabin];
            if(this.total == 1) {
                if(this.paxAdult == 1) this.label = `${this.total} Adult, ${cabin}`;
                else if(this.paxChild == 1) this.label = `${this.total} Child, ${cabin}`;
                else if(this.paxInfant == 1) this.label = `${this.total} Infant, ${cabin}`;
            } else if(this.total > 1)
                this.label = `${this.total} Travelers, ${cabin}`;
            else
                this.label = '';
        },
        autoClose() {
            this.showing = false;
        },
        handleBlur(event) {
            let relatedTarget = event.relatedTarget || event.explicitOriginalTarget || document.activeElement;
            if(!event.target.parentNode.parentNode.parentNode.contains(relatedTarget)) {
                this.showing = false;
            }
        }
    },
    mounted() {
        this.paxAdult = this.adult;
        this.paxChild = this.child;
        this.paxInfant = this.infant;
        this.paxCabin = this.cabin;
        if(this.paxAdult > 0 || this.paxChild > 0 || this.paxInfant > 0) {
            this.setTotal();
        }
        this.setLabel();
    },
    components: {
        'number-input': NumberInput
    },
    directives: {
        'auto-close': AutoCloseDirective
    }
}
</script>

<style scoped lang="scss">
@import "bulma/sass/utilities/all";
.input {
    cursor: pointer;
}
.wrapper {
    position: relative;
    .pax-window {
        background-color: #FFF;
        border: solid 1px rgb(219, 219, 219);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        right: 0;
        min-width: 250px;
        padding: 16px;
        position: absolute;
        top: calc(100% - 1px);
        width: 100%;
        z-index: 50;
    }
}
.field-body {
    flex-grow: 1.5;
}
.field.has-addons {
    width: 100%;
}
</style>
