<template>
    <div>
       <transition name="background">
            <div v-if="playing" class="background" @click="close()"></div>
        </transition>
        <transition name="wrapper">
            <div class="border" :class="{'wrapper': playing}">
                <div class="close" v-if="playing" @click="close()">
                    <span class="icon">
                        <span class="fas fa-times-circle fa-lg"></span>
                    </span>
                </div>
                <figure class="image is-16by9">
                    <iframe
                        class="has-ratio"
                        :src="source"
                        width="640"
                        height="360"
                        frameborder="0"
                        ref="video"
                        allowfullscreen
                        allow="autoplay; encrypted-media"></iframe>
                </figure>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        source: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            video: null,
            playing: false
        }
    },
    methods: {
        close() {
            this.video.pause();
            this.playing = false;
        }
    },
    mounted() {
        this.video = new Vimeo.Player(this.$refs['video']);
        this.video.on('play', () => {
            this.playing = true;
        });
        this.video.on('pause', () => {
            this.playing = false;
        });
        this.video.on('stop', () => {
            this.playing = false;
        });
    },
    beforeDestroy() {
        this.video = null;
    }
}
</script>

<style lang="scss" scoped>
.background {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}
.background-enter-active, .background-leave-active {
    transition: opacity 150ms;
}

.background-enter, .background-leave-to {
    opacity: 0;
}

.border {
    border: solid 1px hsl(0, 0%, 86%);
}
.wrapper {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    left: 50%;
    max-width: 1600px;
    opacity: 1;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 110;
    .close {
        color: #FFF;
        cursor: pointer;
        position: fixed;
        right: 8px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        top: 8px;
        z-index: 155;
    }
}
.wrapper-leave-active {
    transition: opacity 150ms;
}

.wrapper-leave-to {
    opacity: 0;
}
</style>