<template>
    <div class="info-box" :class="{'box': box}">
        <div class="columns is-mobile is-gapless is-vcentered is-marginless header" v-if="label != null || $slots.label || $slots.header">
            <div class="column">
                <span v-if="label != null" class="label">{{label}}</span>
                <slot name="label" v-if="$slots.label" />
            </div>
            <div class="column is-5" v-if="$slots.header">
                <slot name="header"/>
            </div>
        </div>
        <div class="panel" :class="{'panel-padding': panelPad}">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: null
        },
        box: {
            type: Boolean,
            default: true
        },
        panelPad: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss" scoped>
.info-box {
    .box {
        border: solid 1px hsl(0, 0%, 86%);
        box-shadow: none;
    }
    padding: 0rem;
    .has-padding-right {
        padding-right: 8px!important;
    }
    .header {
        border-bottom: solid 1px hsl(0, 0%, 96%);
        padding: 0.5rem .75rem;
        .selected {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }
    .panel-padding {
        padding: .75rem 1rem;
    }
}
</style>
