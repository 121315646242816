<template>
    <div class="toggle" :class="{'closed': !open, 'box': box}">
        <div class="columns is-mobile is-gapless is-vcentered is-marginless header" @click="toggle">
            <div class="column is-narrow is-arrow has-padding-right">
                <span class="fas fa-caret-up"></span>
            </div>
            <div class="column">
                <span v-if="label != null" class="label">{{label}}</span>
                <slot name="label" v-if="$slots.label" />
            </div>
            <div class="column is-5" v-if="$slots.header">
                <slot name="header" v-bind:open="open" />
            </div>
        </div>
        <div class="panel" :class="{'panel-padding': panelPad}">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: null
        },
        value: {
            type: Boolean,
            default: false
        },
        box: {
            type: Boolean,
            default: true
        },
        panelPad: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            open: true
        }
    },
    methods: {
        toggle() {
            this.open = !this.open;
            this.$emit('input', this.open);
        }
    },
    mounted() {
        this.open = this.value;
    },
    watch: {
        value(val) {this.open = val;}
    }
}
</script>

<style lang="scss" scoped>
.toggle {
    &.box {
        border: solid 1px hsl(0, 0%, 86%);
        box-shadow: none;
    }
    padding: 0rem;
    .has-padding-right {
        padding-right: 8px!important;
    }
    .header {
        cursor: pointer;
        border-bottom: solid 1px hsl(0, 0%, 96%);
        padding: 0.5rem .75rem;
        & * {
            cursor: pointer;
        }
        .selected {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
        }
    }
    .panel-padding {
        padding: .75rem 1rem;
    }
    &.closed {
        .fa-caret-up {
            transform: rotate(-180deg);
        }
        .header {
            border-bottom: none;
        }
        .panel {
            height: 0;
            overflow: hidden;
            padding: 0;
        }
    }
}
</style>
