<template>
    <form action="/itineraries.php" method="GET" novalidate autocomplete="off" @submit.prevent="submit" v-if="segments.length > 0">

        <div class="columns is-1 is-variable is-marginless is-multiline">
            <div class="column" :class="layout == 'vertical' ? 'is-12' : 'is-12-tablet is-auto-desktop'">
                {{tabindex = 0 | hide}}
                <div class="columns is-1 is-variable is-multiline" v-for="(segment, segmentIndex) in segments" :key="segmentIndex">
                    <div class="segment-label is-size-7 has-text-grey" v-show="type=='multi'">Segment {{ segmentIndex + 1 }}</div>
                    <div class="column" :class="layout == 'vertical' ? 'is-12' : 'is-6-tablet is-auto-desktop'">
                        <auto-complete
                            placeholder="Going From"
                            icon="plane-departure"
                            size="normal"
                            endpoint="/airports?search={term}"
                            v-model="segment.departure"
                            display="short.text"
                            :tabindex="tabindex += 1"
                            :autofocus="true"
                            :data-vv-scope="`segments_${segmentIndex}`"
                            v-validate="'required'"
                            data-vv-name="departure"
                            data-vv-as="departure airport"
                            >
                                <template v-slot:result="props">
                                    <div class="autocomplete-results">
                                        <p class="has-text-grey-dark is-size-6" v-html="props.result.short.html"></p>
                                        <p class="has-text-grey-light is-size-7" v-html="props.result.search.html"></p>
                                    </div>
                                </template>
                        </auto-complete>
                        <p class="help is-danger" v-show="errors.has(`segments_${segmentIndex}.departure`)">{{ errors.first(`segments_${segmentIndex}.departure`) }}</p>
                    </div>
                    <div class="column" :class="layout == 'vertical' ? 'is-12' : 'is-6-tablet is-auto-desktop'">
                        <auto-complete
                            placeholder="Going To"
                            icon="plane-arrival"
                            size="normal"
                            endpoint="/airports?search={term}"
                            v-model="segment.arrival"
                            display="short.text"
                            :tabindex="tabindex += 1"
                            :data-vv-scope="`segments_${segmentIndex}`"
                            v-validate="'required'"
                            data-vv-name="arrival"
                            data-vv-as="arrival airport">
                                <template v-slot:result="props">
                                    <div class="autocomplete-results">
                                        <p class="has-text-grey-dark is-size-6" v-html="props.result.short.html"></p>
                                        <p class="has-text-grey-light is-size-7" v-html="props.result.search.html"></p>
                                    </div>
                                </template>
                        </auto-complete>
                        <p class="help is-danger" v-show="errors.has(`segments_${segmentIndex}.arrival`)">{{ errors.first(`segments_${segmentIndex}.arrival`) }}</p>
                    </div>
                    <div class="column" :class="layout == 'vertical' ? 'is-12' : 'is-6-tablet is-3-desktop'">
                        <type-date-picker
                            size="normal"
                            placeholder="Departure Date"
                            icon="calendar-alt"
                            v-model="segment.departureDate"
                            format="MMM Do, YYYY"
                            :mindate="segment.departureMinDate"
                            :maxdate="today330"
                            :tabindex="tabindex += 1"
                            @input="date => departureDatePicked(segmentIndex, date)"
                            v-validate="'required'"
                            :data-vv-scope="`segments_${segmentIndex}`"
                            data-vv-name="departureDate"
                            data-vv-as="departure date"
                            >
                        </type-date-picker>
                        <p class="help is-danger" v-show="errors.has(`segments_${segmentIndex}.departureDate`)">{{ errors.first(`segments_${segmentIndex}.departureDate`) }}</p>
                    </div>
                    <div class="column" :class="layout == 'vertical' ? 'is-12' : 'is-6-tablet is-3-desktop'" v-if="type == 'round'">
                        <type-date-picker
                            size="normal"
                            placeholder="Return Date"
                            icon="calendar-alt"
                            v-model="segment.returnDate"
                            format="MMM Do, YYYY"
                            :mindate="segment.returnMinDate"
                            :maxdate="today330"
                            :tabindex="tabindex += 1"
                            v-validate="'required'"
                            :data-vv-scope="`segments_${segmentIndex}`"
                            data-vv-name="returnDate"
                            data-vv-as="return date"
                            >
                        </type-date-picker>
                        <p class="help is-danger" v-show="errors.has(`segments_${segmentIndex}.returnDate`)">{{ errors.first(`segments_${segmentIndex}.returnDate`) }}</p>
                    </div>
                    <div v-if="type == 'multi' && segmentIndex == segments.length - 1" class="column is-12" :class="[layout == 'vertical' ?  '' : 'segment-controls', type == 'multi' ? 'segment-controls-column' : '']">
                        <button type="button" @click="addSegment" :tabindex="tabindex += 1" class="button is-small" v-if="segments.length != maxSegments">+ Add Segment</button>
                        <button type="button" @click="removeSegment" :tabindex="tabindex += 1" class="button is-small" v-if="segmentIndex > 1">-</button>
                    </div>
                </div>

            </div>
            <div class="column" :class="layout == 'vertical' ? 'is-12' : 'is-6-tablet is-one-fifth-desktop'">
                <pax-selector
                    :adult="adult"
                    :child="child"
                    :infant="infant"
                    :cabin="cabin"
                    @adult="pax => adult = pax"
                    @child="pax => child = pax"
                    @infant="pax => infant = pax"
                    @cabin="data => cabin = data"
                    :tabindex="tabindex"
                    v-model="travelers"
                    v-validate="'adt_pax|total_pax|adt_inf_match'"
                    data-vv-name="travelers"
                    data-vv-as="travelers"
                    >
                </pax-selector>
                <p class="help is-danger" v-show="errors.has('travelers')">{{ errors.first('travelers') }}</p>
            </div>
            <div class="column is-12" :class="layout == 'vertical' ? '' : 'advanced-search'">
                <div class="is-relative" v-auto-close="autoClose">
                    <button class="button is-small is-inverted is-dark" type="button" @click="toggleAdvanced()">
                        <span>Advanced Options</span>
                        <span class="icon">
                            <i class="fas fa-caret-down"></i>
                        </span>
                    </button>
                    <div class="card advanced-search-panel" v-if="showAdvanced">
                        <div class="card-content">
                            <div class="content">
                                <div class="columns">
                                    <div class="column">
                                        <label class="label">Flight Options</label>
                                        <div>
                                            <checkbox size="small" v-model="directOnly">Direct Flights Only</checkbox>
                                        </div>
                                        <div>
                                            <checkbox size="small" v-model="includeBasicEconomy">Include Basic Economy</checkbox>
                                        </div>
                                    </div>
                                    <div class="column">
                                        <label class="label">Airline or Alliance</label>
                                        <div class="select is-fullwidth">
                                            <select v-model="airOperator">
                                                <option value="null">Any</option>
                                                <optgroup label="Alliances">
                                                    <option v-for="(alliance, value) in alliances" v-bind:value="value" :key="value">{{alliance}}</option>
                                                </optgroup>
                                                <optgroup label="Airlines">
                                                    <option v-for="(carrier, value) in carriers" v-bind:value="value" :key="value">{{carrier}}</option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column" :class="layout == 'vertical' ? 'is-12' : 'is-6-tablet is-1-desktop'">
                <button type="submit" class="button is-normal is-primary is-fullwidth" :disabled="searching" :class="{'is-loading': searching}" :tabindex="tabindex += 1">
                    <span class="icon is-normal">
                        <i class="fas fa-search"></i>
                    </span>
                </button>
            </div>
        </div>

    </form>
</template>

<script>
import AutoCloseDirective from "@directives/AutoClose/AutoCloseDirective";
import AutoCompleteComponent from "../AutoComplete/AutoComplete.vue";
import TypeDatePickerComponent from '../TypeDatePicker/TypeDatePickerComponent.vue';
import PaxComponent from "../Pax/PaxComponent.vue";
import CheckboxComponent from "../Checkbox/CheckboxComponent.vue";

import { axios, moment } from '@master';

export default {
    props: {
        value: {
            type: Object,
            default: null
        },
        type: {
            type: String,
            defualt: "round"
        },
        layout: {
            type: String,
            default: "horizontal"
        }
    },
    data() {
        return {
            searching: false,
            showAdvanced: false,
            maxSegments: 3,
            today330: moment().add(330, 'days'),
            tripType: '',
            travelers: {},
            adult: 1,
            child: 0,
            infant: 0,
            cabin: "economy",
            directOnly: false,
            includeBasicEconomy: false,
            airOperator: null,
            segments: [],
            alliances: {
                "oneworld": "One World",
                "star": "Star Alliance",
                "skyteam": "SkyTeam"
            },
            carriers: []
        }
    },
    methods: {
        autoClose() {
            this.showAdvanced = false;
        },
        setSearchType(type) {
            this.tripType = type;

            this.$validator.reset();

            if(type == "round" || type == "single") {
                if(this.segments.length == 0) {
                    this.addSegment();
                } else if(this.segments.length >= 1) {
                    this.segments.length = 1;
                }
            } else if(type == "multi") {
                if(this.segments.length == 0) {
                    this.addSegment();
                    this.addSegment();
                } else if(this.segments.length == 1) {
                    this.addSegment();
                }
            }

            if(this.value != null && this.tripType == this.value.tripType) {
                this.setSearchValues(this.value);
            }
        },
        setSearchValues(values) {
            this.adult = values.adult;
            this.child = values.child;
            this.infant = values.infant;
            this.cabin = values.cabin;
            this.directOnly = values.directFlightsOnly;
            this.includeBasicEconomy = values.includeBasicEconomy;
            this.airOperator = values.airOperator;

            let index = 0;
            let minDepartureDate;
            for(let segment of values.segments) {
                this.segments[index] = {
                    key: (Math.random() * 100).toFixed(),
                    departure: segment.departure,
                    arrival: segment.arrival,
                    departureDate: moment(segment.departureDate, 'YYYY-M-D'),
                    departureMinDate: (index < 1) ? moment().add(1, 'days') : moment(values.segments[index - 1].departureDate, 'YYYY-M-D')
                };
                index += 1;
            }
            this.segments[0].returnDate = values.segments[0].returnDate ? moment(values.segments[0].returnDate, 'YYYY-M-D') : null;
            this.segments[0].returnMinDate = moment(values.segments[0].departureDate, 'YYYY-M-D');
        },
        toggleAdvanced() {
            this.showAdvanced = !this.showAdvanced;
        },
        addSegment() {
            if(this.segments.length >= this.maxSegments) return;
            let minDepartureDate = moment().add(1, 'day');
            if(this.segments.length >= 1) {
                minDepartureDate = this.segments[this.segments.length-1].departureDate;
            }
            let segment = {
                key: this.segments.length,
                departure: null,
                arrival: null,
                departureDate: null,
                departureMinDate: minDepartureDate,
                returnMinDate: minDepartureDate
            };
            this.segments.push(segment);
        },
        removeSegment() {
            this.segments.pop();
        },
        submit() {
            // this.$validator.reset();
            this.$validator.validate().then(valid => {
                if(!valid) {
                    return;
                }
                this.searching = true;

                let segments = this.segments.map(segment => {
                    let seg = {
                        departure: segment.departure ? segment.departure.value : null,
                        arrival: segment.arrival ? segment.arrival.value : null,
                        departureDate: segment.departureDate ? segment.departureDate.format('YYYY-M-D') : null
                    };
                    if(this.tripType == 'round') {
                        seg['returnDate'] = segment.returnDate ? segment.returnDate.format('YYYY-M-D') : null;
                    }
                    return seg;
                });

                axios.post('/air', {
                    tripType: this.tripType,
                    cabinClass: this.cabin,
                    directFlightsOnly: this.directOnly,
                    adult: this.adult,
                    child: this.child,
                    infant: this.infant,
                    segments: segments,
                    includeBasicEconomy: this.includeBasicEconomy,
                    airOperator: this.airOperator,
                }).then(response => {

                    this.gaEvent('engagement', 'search', 'Quick Quote Search', {
                        'dimension2': segments[0].departure,
                        'dimension3': segments[0].arrival,
                        'dimension5': this.tripType
                    });

                    window.location.href = `/air/${response.data.searchId}`;
                }).catch(error => {
                    this.searching = false;
                    if(error.status == 401 || error.status == 419) {
                        this.openLoginModal(() => {
                            this.submit();
                        });
                    } else if(error.status == 422) {
                        let errors = error.data.errors;
                        let regex = new RegExp(/[a-z]+.[{\d}].[a-z]+/);
                        for(let fieldName in errors) {
                            if(regex.test(fieldName)) {// if segment error
                                this.errors.add({
                                    scope: fieldName.split('.').slice(0, 2).join('_'),
                                    field: fieldName.split('.').slice(2).join('.'),
                                    msg: errors[fieldName][0]
                                });
                            } else if(fieldName == 'adult' || fieldName == 'child' || fieldName == 'infant') {
                                this.errors.add({
                                    field: 'travelers',
                                    msg: errors['adult'][0]
                                });
                            }
                        }
                    } else {
                        console.error(error);
                        this.swalError(error.data.message, "Error searching.");
                    }
                });
            });
        },
        departureDatePicked(index, date) {
            this.segments[index].departureDate = date;
            this.segments[index].returnMinDate = date;

            if(this.tripType == 'multi' && (index + 1) < this.segments.length) {
                this.segments[(index + 1)].departureMinDate = date;
            }

        }
    },
    mounted() {
        if(!window.sessionStorage.hasOwnProperty('fs_carriers')) {
            axios.get('/carriers').then((results) => {
                this.carriers = results.data;
                window.sessionStorage.setItem('fs_carriers', JSON.stringify(this.carriers));
            });
        } else {
            this.carriers = JSON.parse(window.sessionStorage.getItem('fs_carriers'));
        }
        this.setSearchType(this.type);
    },
    watch: {
        type(type) {
            this.setSearchType(type);
        }
    },
    components: {
        'auto-complete': AutoCompleteComponent,
        'type-date-picker': TypeDatePickerComponent,
        'pax-selector': PaxComponent,
        'checkbox': CheckboxComponent
    },
    directives: {
        'auto-close': AutoCloseDirective,
    }
}
</script>

<style lang="scss" scoped>
.autocomplete-results {
    padding: 8px 16px;
}
@import "bulma/sass/utilities/all";
.columns {
    position: relative;
    .segment-label {
        left: .5rem;
        position:absolute;
        top: -.75rem;
        z-index: 10;
    }
    .segment-controls-column {
        align-items: center;
        display: flex;
        height: 100%;
    }
}
@include desktop() {
    .advanced-search {
        left: 0;
        position: absolute;
        top: 100%;
    }
    .segment-controls {
        left: 100%;
        position: absolute;
        width: 138px;
    }
}
</style>
