<template>
    <div
        :class="{
            'box is-paddingless': !boxless,
            'pt-0': !$slots.header && !$slots.labels
        }
        ">
        <div
            class="columns is-marginless is-vcentered is-mobile is-multiline"
            v-if="$slots.header || selectable"
            >
                <div
                    class="column is-narrow"
                    v-if="selectable"
                    >
                        <checkbox
                            v-model="selectAll"
                            @input="toggleAll"
                            >
                                Select All
                        </checkbox>
                </div>
                <div
                    class="column"
                    v-if="searchable"
                    :class="{'search': selectable, 'is-12-mobile': selectable}"
                    >
                        <input
                            type="search"
                            class="input"
                            v-model="query"
                            :placeholder="searchPlaceholder"
                            />
                </div>
                <div
                    class="column is-narrow"
                    style="margin-left: auto;"
                    >
                        <slot
                            name="header"
                            :checked="checked"
                            />
                </div>
        </div>
        <div class="is-relative loading-bar">
            <BarLoader v-model="loading"/>
        </div>
        <div class="table-container">
            <table class="table is-striped is-hoverable is-fullwidth">
                <thead
                    v-if="$slots.labels"
                    class="has-background-white-ter has-text-grey-dark has-text-weight-semibold"
                    >
                        <tr ref="labels">
                            <td
                                v-if="selectable"
                                class="is-narrow">
                            </td>
                            <slot name="labels" />
                        </tr>
                </thead>
                <tbody>
                    <template v-if="filteredItems.length == 0">
                        <tr>
                            <td :colspan="$slots.labels.length">
                                <p class="has-text-grey-light is-italic">
                                    <slot
                                        name="no_items"
                                        :search="query">
                                        No items
                                        <template v-if="query != ''">
                                            matching "{{query}}"
                                        </template>
                                    </slot>
                                </p>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr
                            v-for="(item, index) in paginatedItems"
                            :key="index"
                            class="is-size-7-half has-text-grey-dark"
                            :style="{'vertical-align': vertAlign}"
                            >
                                <td
                                    v-if="selectable"
                                    >
                                        <checkbox
                                            v-model="item.checked"
                                            @input="selectItem"
                                            :disabled="item.disabled"
                                            >
                                        </checkbox>
                                </td>
                                <slot name="row" :item="item" :index="index"/>
                        </tr>
                    </template>
                </tbody>
                <tfoot v-if="items.length > rowsPerPage">
                    <td :colspan="$slots.labels.length">
                        <nav class="pagination is-small is-pulled-right">
                            <p class="has-text-grey pr-2">{{ (currentPage + 1) }}/{{ (lastPage + 1) }}</p>
                            <button
                                class="pagination-previous button is-light"
                                @click="currentPage--"
                                :disabled="currentPage == 0"
                                >
                                    <span class="fas fa-caret-left"></span>
                            </button>
                            <button
                                class="pagination-next button is-light"
                                @click="currentPage++"
                                :disabled="currentPage == lastPage"
                                >
                                    <span class="fas fa-caret-right"></span>
                            </button>
                        </nav>
                    </td>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import Checkbox from '@components/Checkbox/CheckboxComponent.vue';
import BarLoader from '@components/Loader/BarLoaderComponent.vue';

import { isset } from '@master';

export default {
    props: {
        items: {
            type: Array|Object,
            required: true
        },
        selectable: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false
        },
        sortable: {
            type: Boolean,
            default: false
        },
        searchPlaceholder: {
            type: String,
            default: 'Search...'
        },
        loading: {
            type: Boolean,
            default: false
        },
        boxless: {
            type: Boolean,
            default: false
        },
        vertAlign: {
            type: String,
            default: 'middle'
        }
    },
    data() {
        return {
            selectAll: false,
            query: '',
            sortables: {},
            currentPage: 0,
            rowsPerPage: 20
        }
    },
    methods: {
        toggleAll() {
            this.filteredItems.map(item => {
                if(!item.disabled) {
                    item.checked = this.selectAll;
                }
                return item;
            })
        },
        selectItem() {
            if(this.checked.length < this.enabled.length) {
                this.selectAll = false;
            } else if(this.checked.length == this.enabled.length) {
                this.selectAll = true;
            }
        },
        setItemDefaults(item) {
            this.$set(
                item,
                'checked',
                isset(item.checked) ? item.checked : this.selectAll
            );
            this.$set(
                item,
                'disabled',
                isset(item.disabled) ? item.disabled : false
            );
        },
        sortItems() {
            this.items = this.items.sort((a, b) => {
                let result = 0;
                for(const sortableIndex of Object.keys(this.sortables)) {
                    let vala = this.sortables[sortableIndex].sortable.split('.').reduce((o,i) => o[i], a);
                    let valb = this.sortables[sortableIndex].sortable.split('.').reduce((o,i) => o[i], b);
                    if(result === 0) {
                        result = Number.parseInt(this.sortables[sortableIndex].sortableDir) * (vala.toString() < valb.toString() ? -1 : vala.toString() > valb.toString() ? 1 : 0);
                    }
                }
                return result;
            });
        }
    },
    mounted() {
        if(this.selectable) {
            this.items.map(item => {
                this.setItemDefaults(item);
                return item;
            });
        }
        if(this.sortable && this.items.length > 1) {
            for(const child of this.$refs.labels.children) {
                if(child.dataset.sortable != undefined) {
                    child.classList.add('data-table-sortable-element');

                    const sortableArrows = document.createElement('span');
                    sortableArrows.classList.add('data-table-sortable-arrows');
                    child.append(sortableArrows);

                    switch(child.dataset.sortableDir) {
                        case undefined:
                        case 'neutral':
                            child.dataset.sortableDir = 0;
                            break;
                        case 'desc':
                            child.dataset.sortableDir = -1;
                            break;
                        case 'asc':
                            child.dataset.sortableDir = 1;
                            break;
                    }
                    sortableArrows.classList.add(child.dataset.sortableDir == undefined ? 'neutral' : child.dataset.sortableDir);

                    child.addEventListener('click', () => {
                        sortableArrows.classList.remove('neutral', 'desc', 'asc');

                        switch(Number.parseInt(child.dataset.sortableDir)) {
                            case 0:
                                sortableArrows.classList.add('desc');
                                break;
                            case -1:
                                sortableArrows.classList.add('asc');
                                break;
                            case 1:
                                sortableArrows.classList.add('neutral');
                                break;
                        }

                        switch(Number.parseInt(child.dataset.sortableDir)) {
                            case 0:
                                child.dataset.sortableDir = -1;
                                break;
                            case -1:
                                child.dataset.sortableDir = 1;
                                break;
                            case 1:
                                child.dataset.sortableDir = 0;
                                break;
                        }

                        const sortableKey = child.dataset.sortable.replace(/[^A-Za-z]+/g, '');
                        switch(Number.parseInt(child.dataset.sortableDir)) {
                            case 1:
                                this.sortables[sortableKey] = child.dataset;
                                break;
                            case -1:
                                this.sortables[sortableKey] = child.dataset;
                                break;
                            case 0:
                                if(isset(this.sortables[sortableKey])) {
                                    delete this.sortables[sortableKey];
                                }
                                break;
                        }

                        this.sortItems();
                    }, false);
                }
            }
            this.sortItems();
        }
    },
    beforeDestroy() {
        for(const child of this.$refs.labels.children) {
            child.removeEventListener('click', () => {});
        }
    },
    computed: {
        checked() {
            return this.filteredItems.filter(item => item.checked);
        },
        enabled() {
            return this.items.filter(item => !item.disabled);
        },
        filteredItems() {
            if(this.query == '') {
                return this.items;
            }
            return this.items.filter(item => {
                if((Object.values(item).join('').toLowerCase()).includes(this.query.toLowerCase())) {
                    return true;
                }
                return false;
            });
        },
        paginatedItems() {
            return this.filteredItems.slice(this.currentPage * this.rowsPerPage, (this.currentPage * this.rowsPerPage) + this.rowsPerPage);
        },
        lastPage() {
            return Math.floor(this.filteredItems.length / this.rowsPerPage);
        }
    },
    watch: {
        items(newItems) {
            if(this.selectable) {
                return newItems.map(item => {
                    this.setItemDefaults(item);
                    return item;
                });
            }
        }
    },
    components: {
        Checkbox,
        BarLoader
    }
}
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/all";
.table {
    tr {
        vertical-align: middle;
    }
    td {
        white-space: nowrap;
    }
}
.data-table-sortable-element {
    cursor: pointer;
    padding-right: 24px;
    position: relative;
}
@include mobile() {
    .search {
        order: 3;
    }
}
</style>

<style lang="scss">
.data-table-sortable-arrows {
    color: hsl(0, 0%, 80%);
    font-family: "Font Awesome 5 Free";
    height: 100%;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    &.asc:before {
        color: hsl(0, 0%, 40%);
    }
    &.desc:after {
        color: hsl(0, 0%, 40%);
    }
    &:before {
        content: '\f0d8';
        position: absolute;
        top: 8px;
        right: 0;
    }
    &:after {
        bottom: 8px;
        content: '\f0d7';
        position: absolute;
        right: 0;
    }
}
</style>
