<template>
    <div class="is-pulled-right" ref="recaptcha"></div>
</template>

<script>
export default {
    props: {
        sitekey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            recaptcha: ''
        }
    },
    methods: {
        captchaLoaded() {
            this.recaptcha = window.grecaptcha.render(this.$refs.recaptcha, {
                'sitekey' : this.sitekey,
                'theme' : 'light',
                'callback': () => {
                    this.$emit('input', window.grecaptcha.getResponse(this.recaptcha));
                }
            });
        }
    },
    mounted() {
        window.captchaOnload = this.captchaLoaded;
    }
}
</script>