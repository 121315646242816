import Swal from './Swal.vue';

export default {
    install(Vue, opts) {
        const root = new Vue({
            render: createElement => createElement(Swal)
        })
        root.$mount(document.body.appendChild(document.createElement('div')));

        Vue.mixin({
            methods: {
                swal(alert) {
                    root.$emit('alert', alert);
                },
                swalConfirm(content, title = null, buttons = null) {
                    this.swal({
                        type: "confirm",
                        title: title,
                        content: content,
                        buttons: buttons
                    });
                },
                swalError(content, title = null, buttons = null) {
                    if(buttons == null) {
                        buttons = [{value: "Close", close: true}];
                    }
                    this.swal({
                        type: "error",
                        title: title,
                        content: content,
                        buttons: buttons
                    });
                },
                swalWarning(content, title = null, buttons = null) {
                    this.swal({
                        type: "warning",
                        title: title,
                        content: content,
                        buttons: buttons
                    });
                },
                swalSuccess(content, title = null, buttons = null) {
                    if(buttons == null) {
                        buttons = [{value: "Close", close: true}];
                    }
                    this.swal({
                        type: "success",
                        title: title,
                        content: content,
                        buttons: buttons
                    });
                }
            }
        });

    }
}
