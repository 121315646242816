function getTitle(vm) {
    const { title } = vm.$options
    if(title) {
        return title;
    } else {
        return null;
    }
}

export default {
    created() {
        let vm = this;
        const title = getTitle(this);
        if(title) {
            document.title = `${title} :: Flight Sugar`;
        }
    },
    methods: {
        setTitle(title) {
            if(title) {
                document.title = `${title} :: Flight Sugar`;
            } else {
                document.title = '';
            }
        }  
    },
}