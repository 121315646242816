<template>
  <div class="box is-paddingless">
    <div
      v-for="(fare, index) in fares"
      :key="index"
      class="columns is-marginless is-mobile is-vcentered is-multiline this-is-a-test"
      >
        <div class="column is-12-touch is-2-desktop">
          <label class="label has-text-right-desktop has-text-weight-semibold">Per {{ fare.travelerTypeLabel }}</label>
        </div>
        <div class="column is-12-touch is-5-desktop">
          <div class="field has-addons">
            <div class="control">
              <button class="button is-static" tabindex="-1">$</button>
            </div>
            <div class="control is-expanded">
              <input
                type="number"
                class="input"
                v-model.number="fare.businessCommission"
                step="0.01"
              />
            </div>
          </div>
        </div>
        <div class="column is-6-touch is-3-desktop has-text-centered-desktop has-text-grey-dark">
          <span class="fas fa-times fa-xs"></span>
          <span>{{ fare.numTravelers }} Travelers</span>
        </div>
        <div class="column is-6-touch is-2-desktop has-text-grey-dark">
          <div class="has-text-weight-semibold has-text-right">
            {{ (fare.businessCommission * fare.numTravelers) | toCurrency }}
          </div>
        </div>
    </div>

    <div
      class="columns is-marginless has-text-weight-semibold is-mobile"
      style="border-bottom: solid 1px hsl(0, 0%, 86%)"
    >
      <div class="column is-narrow">Total Commission</div>
      <div class="column has-fullwidth-underline"></div>
      <div class="column is-narrow">
        {{ fares.reduce((total, fare) => total += fare.businessCommission * fare.numTravelers, 0) | toCurrency }}
      </div>
    </div>

    <div class="columns is-vcentered is-marginless has-background-white-bis">
      <div class="column">
        <checkbox
            :value="collectCommission"
            @input="(value) => setCollectCommission(value)"
            style="display: flex"
          >
          Collect commission at the time of purchase and remit to my
          business.&nbsp;
          <tooltip position="top" :underline="false" :custom="true">
            <template slot="tooltip">
              <div class="card">
                <div class="card-content">
                  By selecting this option, you will have Flight Sugar collect
                  your<br />
                  commission during purchase and remit it back to your business
                  via a check.<br />
                  <br />
                  If you choose not to select this option, Flight Sugar will not
                  collect commission<br />
                  and will not send a commission check. The pricing will be
                  adjusted on the<br />
                  Traveler Quote, and Traveler Receipt so the total price
                  includes your commission.
                </div>
              </div>
            </template>
            <template slot="content">
              <span class="icon is-small">
                <i class="fas fa-question-circle"></i>
              </span>
            </template>
          </tooltip>
        </checkbox>
      </div>
      <div class="column is-narrow">
        <button
          class="button is-inverted is-primary"
          :class="{
            'is-outlined': saving,
            'is-loading': saving
          }"
          type="button"
          @click="saveCommission()"
          :disabled="saving"
          >
          Save Commission
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from "@components/Tooltip/TooltipComponent.vue";
import Checkbox from "@components/Checkbox/CheckboxComponent.vue";
import { axios, isset } from '@master';

export default {
  props: {
    bookingId: {
      type: Number,
      required: true
    },
    fares: {
      type: Array,
      required: true,
    },
    tabbed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      collectCommission: false
    }
  },
  methods: {
    getCommissionsByTravelerType() {
      let commissions = {};
      for(let fare of this.fares) {
        commissions[fare.travelerType] = fare.businessCommission;
      }
      return commissions;
    },
    setCollectCommission(value) {
      this.fares.map(fare => fare.collectCommission = value);
      this.collectCommission = value;
    },
    async saveCommission() {
      this.saving = true;
      try {
        const payload = {
          commissions: this.getCommissionsByTravelerType(),
          collectCommission: this.collectCommission
        };
        await axios.put(`/bookings/${this.bookingId}/update/commission`, payload);
        this.addAlert('Commission has been saved successfully!', 'is-success');
        this.saving = false;
      } catch (error) {
        this.saving = false;
        if(isset(error.data)) {
          this.swalError(error.data.message);
        } else {
          this.swalError(error);
        }
      }
    },
  },
  mounted() {
    this.collectCommission = this.fares.map(fare => fare.collectCommission).some(collect => collect == true);
  },
  components: {
    Tooltip,
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/all";
.box {
  &.tabbed {
    border-top: none;
  }
}
@include touch() {
  .this-is-a-test {
    padding: 16px!important;
    .column {
      padding: 0;
    }
  }
}
</style>
