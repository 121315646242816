<template>
    <div class="bar-loader" v-if="value"></div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.bar-loader {
    height: 2px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    &:before {
        animation: animate 1s linear infinite;
        background-color: #e05f52;
        content: "";
        height: 2px;
        left: -10%;
        position: absolute;
        top: 0;
        width: 10%;
    }
}

@keyframes animate {
    from {left: -10%;width:10%}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 100%;}
    to {left: 100%;}
}

</style>
