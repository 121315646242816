<template>
    <div class="slider-container">
        <div class="slider-bar">
            <button
                class="slider-button"
                v-for="(position, index) in buttonList"
                :key="index"
                :style="{ 'left': `${position}%` }"
                @mousedown.prevent="handleMouseDown(index)"
                @touchstart="handleTouchDown(index)"
                >
            </button>
            <div class="slider-bar-tween" :style="{ 'left': `${bar.left}%`, 'width': `${bar.width}%` }" v-if="tween != null"></div>
        </div>
    </div>
</template>

<script>

function killListener() {}

export default {
    props: {
        buttons: {
            type: Object,
            default: () => { return { button: 0 } }
        },
        range: {
            type: Object,
            default: () => { return { min: 0, max: 100} }
        },
        tween: {
            type: Boolean|Object,
            default: null
        }
    },
    data() {
        return {
            element: { x: null, w: null },
            buttonList: {},
            dragging: false,
            selectedIndex: null,
            bar: {
                left: 0,
                width: 100
            }
        };
    },
    methods: {
        handleMouseDown(index) {
            this.selectedIndex = index;
            window.addEventListener('mousemove', this.handleMouseMove, false);
            window.addEventListener('mouseup', this.handleMouseUp, false);
        },
        handleTouchDown(index) {
            this.selectedIndex = index;
            window.addEventListener('touchmove', this.handleMouseMove);
            window.addEventListener('touchend', this.handleMouseUp);
        },
        handleMouseMove($event) {
            if($event.type == "touchmove") {
                this.offset = ($event.touches[0].screenX - this.element.x) / this.element.w;
            } else {
                this.offset = ($event.x - this.element.x) / this.element.w;
            }

            if(this.offset <= 0) {
                this.offset = 0;
            } else if(this.offset >= 1) {
                this.offset = 1;
            }
            this.buttonList[this.selectedIndex] = this.offset * 100;

            if(this.tween != null) {
                this.bar.left = this.buttonList[this.tween[0]];
                this.bar.width = this.buttonList[this.tween[1]] - this.buttonList[this.tween[0]];
            }

            this.$emit('drag', this.percentsToValue(this.buttonList));
        },
        handleMouseUp() {
            window.removeEventListener('mousemove', this.handleMouseMove, false);
            window.removeEventListener('mouseup', this.handleMouseUp, false);
            window.removeEventListener('touchmove', this.handleMouseMove, false);
            window.removeEventListener('touchend', this.handleMouseUp, false);
            this.selectedIndex = null;
            this.$emit('drop', this.percentsToValue(this.buttonList));
        },
        valuesToPercent(values) {
            let percent = null;
            for(let value in values) {
                percent = ((values[value] - this.range.min) / (this.range.max - this.range.min));
                if(percent <= 0) {
                    percent = 0;
                } else if(percent >= 1) {
                    percent = 1;
                }
                this.$set(this.buttonList, value, percent * 100);
            }
        },
        percentsToValue(percents) {
            let value = null;
            let values = {};
            for(let percent in percents) {
                value = ((this.range.max - this.range.min) * (percents[percent] / 100)) + this.range.min;
                values[percent] = value;
            }
            return values;
        },
        setTween() {
            if(this.tween != null) {
                this.bar.left = this.buttonList[this.tween[0]];
                this.bar.width = this.buttonList[this.tween[1]] - this.buttonList[this.tween[0]];
            }
        }
    },
    mounted() {
        this.element.x = this.$el.getBoundingClientRect().left + 16;
        this.element.w = this.$el.clientWidth - 32;

        this.valuesToPercent(this.buttons);
        this.setTween();
    },
    watch: {
        buttons(buttons) {
            this.valuesToPercent(buttons);
            this.setTween();
        }
    }
}
</script>

<style lang="scss" scoped>
    .slider-container {
        height: 1.5rem;
        position: relative;
        .slider-bar {
            background-color: hsl(0,0%,84%);
            border-radius: .125rem;
            height: .15rem;
            left: 16px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: calc(100% - 32px);
        }
        .slider-bar-tween {
            background-color:#e05f52;
            height:2px;
            position:absolute;
            top:0px;
            z-index: -1;
        }
        .slider-button {
            background-color: #e05f52;
            border: solid 4px #FFF;
            border-radius: 50%;
            box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            height: 18px;
            left: 0;
            padding: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 18px;
            &:focus, &:active {
                box-shadow: 0;
                outline: none;
            }
        }
    }
</style>