<template>
    <label class="checkbox" :class="`is-${size}`">
        <input type="checkbox" :value="nativeValue" v-model="computedValue" :disabled="disabled" >
        <span class="check"></span>
        <span class="check-label" v-if="this.$slots.default"><slot /></span>
    </label>
</template>

<script>
export default {
    props: {
        value: [Boolean, Array, Object],
        nativeValue: [Number, String, Object],
        disabled: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "normal"
        }
    },
    data() {
        return {
            newValue: this.value
        }
    },
    computed: {
        computedValue: {
            get() {
                return this.newValue
            },
            set(value) {
                if(this.disabled == null || this.disabled == false) {
                    this.newValue = value
                    this.$emit('input', value)
                }
            }
        }
    },
    watch: {
        value(value) {this.newValue = value;}
    }
}
</script>

<style lang="scss" scoped>
.checkbox {
    align-items: center;
    color: #4A4A4A;
    display: inline-flex;
    font-size: 1.0rem;
    outline: none;
    input[type="checkbox"] {
        opacity: 0;
        z-index: -1;
        width: 1.5em;
        &:checked + .check:after {
            color: #e05f52;
            content: "\f00c";
            font-family: "Font Awesome 5 Free";
            font-size: .75em;
            font-weight: 900;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%,-50%);
        }
        &:focus + .check {
            border: solid 1px #3273dc;
            box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
        }
        &:hover + .check {
            border: solid 1px #3273dc;
        }
        &:disabled + .check {
            background-color: hsl(0,0%,96%);
            border: solid 1px hsl(0,0%,96%);
            box-shadow: none;
            color: hsl(0,0%,71%);
        }
    }
    .check {
        align-items: center;
        background-color: #FFF;
        border: solid 1px #4A4A4A;
        display: inline-flex;
        height: 1.25em;
        justify-content: center;
        position: absolute;
        text-align: center;
        width: 1.25em;
    }
    .check-label {
        display: flex;
        flex: 1;
        padding-left: .5em;
    }
    &.is-small {
        font-size: .75em;
    }
    &.is-medium {
        font-size: 1.25em;
    }
    &.is-large {
        font-size: 1.5em;
    }
}
</style>
