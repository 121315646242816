<template>
    <div class="loading-wrapper">
        <div class="loading-screen is-loading" v-if="value">
            <div class="loading-icon"></div>
        </div>
        <div class="loading-content" :class="{'blur': value}">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>

@import "bulma/sass/utilities/all";

.loading-wrapper {
    position: relative;
    .loading-screen {
        align-items: center;
        background-color: rgba(255, 255, 255, 0.25);
        bottom: 0;
        flex: 1;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 40;
    }
    .loading-content.blur {
        filter: blur(2px);
    }
    .loading-icon {
        @include loader();
        width: 3em;
        height: 3em;
    }
}
</style>
