<template>

  <div class="wrapper" v-auto-close="autoClose">
    <div class="field has-addons is-marginless">
      <span class="control" v-if="!this.hideButton">
        <button type="button" class="button" @click="buttonOnClick" @blur="buttonOnBlur" tabindex="-1">
          <i :class="`fas fa-${icon}`" :style="`opacity: ${this.isFocused ? 0.9 : 0.2};`"/>
        </button>
      </span>
      <span class="control is-width-100">
        <input
            :value="this.displayedValue"
            class="input"
            :class="`is-${size}`"
            ref="input"
            type="text"
            :placeholder="placeholder"
            autocomplete="off"
            :tabindex="tabindex"
            :autofocus="autofocus"
            @blur="inputOnBlur"
            @change="checkIfNeedsProcessed()"
            @input="liveUpdateValue"
            @focus="makeEditable"
            :disabled="disabled"
        />
      </span>
    </div>

    <div class="panel" v-if="showPicker">
      <div class="columns is-mobile">
        <div class="column is-2">
          <button class="button is-primary is-inverted is-small is-expanded" tabindex="-1" type="button" @mousedown="updateCalendar('prev', $event)">
            <span class="icon">
              <i class="fas fa-arrow-left"/>
            </span>
          </button>
        </div>
        <div class="column is-8 has-text-centered is-size-4">{{months[current.month()]}} {{current.year()}}</div>
        <div class="column is-2 has-text-right">
          <button class="button is-primary is-inverted is-small is-expanded" tabindex="-1" type="button" @mousedown="updateCalendar('next', $event)">
            <span class="icon">
              <i class="fas fa-arrow-right"/>
            </span>
          </button>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column has-text-centered is-size-7" v-for="(day, index) in daysOfWeek" :key="index">{{day}}</div>
      </div>
      <div class="columns is-mobile" v-for="(week, index) in month" :key="index">
        <div class="column has-text-centered is-size-7" v-for="(day, dayIndex) in week" :key="dayIndex" @mousedown="selectDate(day, $event)" :class="{'today': isToday(day), 'date': day != null, 'selected': isSelected(day), 'disabled': isDisabled(day)}">
          <span v-if="day != null">{{ day.date() }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AutoCloseDirective from "@directives/AutoClose/AutoCloseDirective";
import {cloneDeep} from "lodash";
import { isset, moment } from '@master';

export default {
  props: {
    value: Object,
    placeholder: String,
    mindate: Object,
    maxdate: Object,
    icon: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "normal"
    },
    format: {
      type: String,
      default: 'YYYY-M-d'
    },
    tabindex: {
      type: Number,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayedValue: String,
      newValue: null,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      daysOfWeek: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
      month: [],
      today: moment(),
      current: null,
      showPicker: false,
      htmlEvent: null,
      isFocused: false,
    };
  },
  methods: {
      buttonOnClick() {
        this.showPicker = true;
        this.isFocused = true;
    },
    buttonOnBlur() {
        this.showPicker = false;
        this.isFocused = false;
    },
    inputOnBlur() {
        this.showPicker = false;
        this.isFocused = false;
        this.processInput();
    },
    generateCalendar(year, month) {
      this.current = moment({year: year, month: month});

      let daysInMonth = this.current.daysInMonth();
      let dayOfWeek = this.current.startOf('month').day();

      let week = 0;
      this.month.length = 0;
      this.month[week] = [];

      for (var i = 0; i < dayOfWeek; i++) {
        this.month[week].push(null);
      }

      for (var i = 1; i <= daysInMonth; i++) {
        if (this.month[week].length % 7 == 0) {
          week++;
          this.month[week] = [];
        }
        this.month[week].push(moment({year: this.current.year(), month: this.current.month(), date: i}));
      }

      for (var i = this.month[this.month.length - 1].length; i < 7; i++) {
        this.month[this.month.length - 1].push(null);
      }
    },
    updateCalendar(direction, event) {
      event.preventDefault();
      event.stopPropagation();

      let month = this.current.month();
      let year = this.current.year();

      if (direction == 'next') {
        month = this.current.add(1, 'month').month();
        year = this.current.year();
      }

      if (direction == 'prev') {
        month = this.current.subtract(1, 'month').month();
        year = this.current.year();
      }

      this.generateCalendar(year, month);
    },
    liveUpdateValue(e) {
      this.displayedValue = e.target.value;
    },
    checkIfNeedsProcessed() {
      let oldVal = this.displayedValue;
      let newVal = moment(this.displayedValue).format('MMM Do, YYYY').toString();

      if (oldVal !== newVal) {
        this.processInput();
      }
    },
    processInput() {
      /*
       * REGEX
       */

      // 1. (D)DMMM
      let DDMMM = /^[0-9]{1,2}[A-Za-z]{3}$/;

      // 2. (D)DMMMYY
      let DDMMMYY = /^[0-9]{1,2}[A-Za-z]{3}[0-9]{2}$/;

      // 3. (M)M/(D)D/YY
      let MMDDYY = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2}$/;

      // 4. (M)M/(D)D/YYYY
      let MMDDYYYY = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;

      // 5. (M)M/(D)D
      let MMDD = /^[0-9]{1,2}\/[0-9]{1,2}$/;

      let autoChangeYear = false;

      if (DDMMM.test(this.displayedValue) || MMDD.test(this.displayedValue)) {
        autoChangeYear = true;
      }
      else if (MMDDYY.test(this.displayedValue) || MMDDYYYY.test(this.displayedValue) || DDMMMYY.test(this.displayedValue)) {
        autoChangeYear = false;
      }
      else if (this.newValue !== null && this.displayedValue !== this.newValue.format('MMM Do, YYYY')) {
        this.displayedValue = '';
        return;
      }

      /*
        * END REGEX
       */

      let dayInst = moment(this.displayedValue);

      if (autoChangeYear) {
        if (dayInst.isBefore(this.today)) {
          dayInst = dayInst.set('year', this.today.year());
          if (dayInst.isBefore(this.today)) {
            dayInst = dayInst.add(1, 'year');
          }
        }
      }

      if (!dayInst.isValid()) {
        dayInst = moment(this.newValue);
      }

      if (dayInst.isValid()) {
        this.newValue = dayInst;
        this.displayedValue = dayInst.format('MMM Do, YYYY');
      }

      else {
        this.newValue = null;
        this.displayedValue = '';
      }

      this.emitInput();
    },
    emitInput() {

      this.$emit('input', this.newValue);
      //this.$refs.input.dispatchEvent(this.htmlEvent);
    },
    makeEditable() {
        this.showPicker = false;
      this.isFocused = true;

      if (this.newValue) {
        this.displayedValue = this.newValue.format('MM/DD/YYYY');
      }
    },
    selectDate(day, event) {
      event.preventDefault();
      event.stopPropagation();
      if(this.isDisabled(day)) return false;
      this.showPicker = false;
      this.newValue = day;
      this.displayedValue = day.format('MMM Do, YYYY');
      this.emitInput();
    },
    isToday(day) {
      return day != null && day.date() == this.today.date() && day.month() == this.today.month();
    },
    isSelected(day) {
      return day != null && this.newValue != null && day.date() == this.newValue.date() && day.month() == this.newValue.month() && day.year() == this.newValue.year();
    },
    isDisabled(day) {
      if(day != null && isset(this.mindate) && day.isBefore(this.mindate)) {
        return true;
      }
      if(day != null && isset(this.maxdate) && day.isAfter(this.maxdate)) {
        return true;
      }
      return false;
    },
    autoClose() {
        this.showPicker = false;
        this.isFocused = false;
    }
  },
  mounted() {
    let valueCpy = cloneDeep(this.value);
    if (this.value) {
      this.displayedValue = valueCpy.format('MMM Do, YYYY');
      this.newValue = valueCpy;
    }

    else {
      this.displayedValue = '';
      valueCpy = moment().add(2, 'day');
    }

    this.generateCalendar(valueCpy.year(), valueCpy.month());

    this.htmlEvent = new Event('input');
  },
  watch: {
    value(val) {
      this.newValue = val;
    },
    mindate: function(newDate) {
      if(newDate != null) {
        this.generateCalendar(newDate.year(), newDate.month());
      }
    },
    displayedValue: function(newVal) {
    }
  },
    directives: {
        'auto-close': AutoCloseDirective
    }
}

</script>

<style scoped lang="scss">
@import "bulma/sass/utilities/all";
.wrapper {
  position: relative;
  .input {
    cursor: text;
  }
  .panel {
    background-color: #FFF;
    border: solid 1px rgb(219, 219, 219);
    left: 0px;
    max-width: 350px;
    padding: 16px;
    position: absolute;
    top: calc(100% - 1px);
    width: 100%;
    z-index: 50;
    .date {
      border: solid 1px #FFF;
    }
    .date:not(.disabled):hover {
      border: solid 1px #e05f52;
      color: #e05f52;
        cursor: pointer;
    }
    .disabled {
      color: hsl(0, 0%, 86%);
    }
    .today {
      background-color: hsl(0, 0%, 96%);
    }
    .selected {
      background-color: #e05f52!important;
      color: #FFF;
    }
    .selected:hover {
      color: #FFF;
    }
  }
}
@include desktop() {
  .wrapper .panel {
    width: 200%;
  }
}

.is-width-100 {
  width: 100%;
}
</style>
