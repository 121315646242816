<template>
    <modal :value="showLoginModal" @input="modalVisibilityChange" class="login-form" :content-modifiers="['is-paddingless']">
        <p class="delete" @click="modalVisibilityChange(false)"></p>
        <login :reset-errors="resetErrors" @success="loginSuccess"></login>
    </modal>
</template>

<script>
import ModalComponent from "@components/Modal/ModalComponent.vue";
import LoginComponent from "@components/Login/LoginComponent.vue";
export default {
    data() {
        return {
            showLoginModal: false,
            resetErrors: false
        }
    },
    methods: {
        loginSuccess($event) {
            this.$root.$emit('showLoginModal', false);
            this.$root.$emit('success', $event);
        },
        modalVisibilityChange(state) {
            if(state == true) {
                this.showLoginModal = true;
                document.getElementById('main_content').style.filter = 'blur(5px)';
                this.resetErrors = false;
            } else {
                this.showLoginModal = false;
                document.getElementById('main_content').style.filter = 'blur(0px)';
                this.resetErrors = true;
            }
        }
    },
    mounted() {
        this.$root.$on('showLoginModal', (showLoginModal) => {
            this.modalVisibilityChange(showLoginModal);
        });
    },
    components: {
        'modal': ModalComponent,
        'login': LoginComponent
    }
}
</script>

<style>
.delete {
    position: absolute;
    right: .5rem;
    top: .5rem;
}
</style>
