<template>
    <div>
        <button class="button is-primary is-fullwidth" @click="show = true">
            <span class="icon is-small"><i class="fas fa-plus"></i></span>
            <span>Add Business</span>
        </button>
        <modal v-model="show" class="admin-business-create">
            <template slot="header">
                Create Business
            </template>

            <div
                class="notification is-danger"
                v-if="duplicates && duplicates.length > 0"
            >
                <p><strong>Is this a duplicate business?</strong></p>
                <p
                    v-for="(duplicate, index) in duplicates"
                    :key="index"
                    class="mb-0"
                >
                    <a :href="`/admin/businesses/${duplicate.id}`" target="_blank">{{ duplicate.name }}</a>
                </p>
            </div>

            <form method="post" @submit.prevent="createBusiness" autocomplete="off">

                <div class="columns is-multiline">
                    <div class="column is-8">
                        <div class="field">
                            <label class="label is-small is-required">Business Name:</label>

                            <div class="control has-icons-right">
                                <input
                                    type="text"
                                    class="input"
                                    v-model="name"
                                    v-validate="'required'"
                                    name="name"
                                    data-vv-as="business name"
                                    @blur="handleNameChange"
                                >
                                <span class="icon is-small is-right" v-if="isSearching">
                                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                </span>
                            </div>
                            <p class="help is-danger" v-show="errors.has('name')">{{ errors.first('name') }}</p>
                        </div>
                    </div>
                    <div class="column is-4">
                        <div class="field">
                            <label class="label is-small is-required">Business Type:</label>
                            <div class="select is-fullwidth">
                                <select
                                    v-model="businessType"
                                    v-validate="'required'"
                                    name="type"
                                    data-vv-as="business type"
                                    @change="setBusinessType"
                                    >
                                        <option value="">--</option>
                                        <option value="reseller">Reseller</option>
                                        <option value="university">University</option>
                                        <option value="humanitarian">Humanitarian</option>
                                        <option value="other">Other</option>
                                </select>
                            </div>
                            <p class="help is-danger" v-show="errors.has('type')">{{ errors.first('type') }}</p>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6">
                        <div class="field">
                            <label class="label is-small is-required">Address:</label>
                            <input
                                type="text"
                                class="input"
                                v-model="address"
                                v-validate="'required'"
                                name="address"
                                data-vv-as="address"
                                >
                            <p class="help is-danger" v-show="errors.has('address')">{{ errors.first('address') }}</p>
                        </div>
                    </div>
                    <div class="column is-6">
                        <div class="field">
                            <label class="label is-small">Address Line 2:</label>
                            <input
                                type="text"
                                class="input"
                                v-model="address2"
                                >
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-3">
                        <div class="field">
                            <label class="label is-small is-required">City:</label>
                            <input
                                type="text"
                                class="input"
                                v-model="city"
                                v-validate="'required'"
                                name="city"
                                data-vv-as="city"
                                >
                            <p class="help is-danger" v-show="errors.has('city')">{{ errors.first('city') }}</p>
                        </div>
                    </div>
                    <div class="column is-3">
                        <div class="field">
                            <label class="label is-small" :class="{'is-required': country == 'USA'}">State:</label>
                            <div class="select is-fullwidth">
                                <select
                                    v-model="state"
                                    v-validate="country == 'USA' ? 'required' : ''"
                                    name="state"
                                    data-vv-as="state"
                                    >
                                        <option value="">------</option>
                                        <option v-for="(state, index) in listStates" :key="index" :value="index">{{state}}</option>
                                </select>
                                <p class="help is-danger" v-show="errors.has('state')">{{ errors.first('state') }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="column is-3">
                        <div class="field">
                            <label class="label is-small is-required">Postal Code:</label>
                            <input
                                type="text"
                                class="input"
                                v-model="postalCode"
                                v-validate="'required'"
                                name="postalCode"
                                data-vv-as="postal code"
                                >
                            <p class="help is-danger" v-show="errors.has('postalCode')">{{ errors.first('postalCode') }}</p>
                        </div>
                    </div>
                    <div class="column is-3">
                        <div class="field">
                            <label class="label is-small is-required">Country:</label>
                            <div class="select is-fullwidth">
                                <select
                                    v-model="country"
                                    v-validate="'required'"
                                    name="country"
                                    data-vv-as="country"
                                    >
                                        <option v-for="(country, index) in listCountries" :key="index" :value="index">{{country}}</option>
                                </select>
                                <p class="help is-danger" v-show="errors.has('country')">{{ errors.first('country') }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <label class="label is-small is-required">Fare Types:</label>
                <BoxLoader v-model="loadingFareTypes">
                    <div class="columns mb-5">
                        <div
                            class="column"
                            v-for="(availableFareType, availableFareTypeIndex) in availableFareTypes"
                            :key="availableFareTypeIndex"
                            >
                                <checkbox v-model="selectedFareTypes" :native-value="availableFareTypeIndex" :disabled="availableFareType.disabled">{{ availableFareType.value }}</checkbox>
                        </div>
                    </div>
                </BoxLoader>

                <div class="buttons is-right">
                    <button class="button is-primary" :disabled="formIsInvalid || processing" :class="{'is-loading': processing}" >Create Business</button>
                </div>

            </form>
        </modal>
    </div>
</template>

<script>
import CheckboxComponent from "@components/Checkbox/CheckboxComponent.vue";
import ModalComponent from "@components/Modal/ModalComponent.vue";
import BoxLoader from "@components/Loader/BoxLoaderComponent.vue";
import { axios } from '@master';

export default {
    props: {
        states: Array|Object,
        countries: Array|Object
    },
    data() {
        return {
            show: false,
            processing: false,
            name: "",
            customerId: "",
            vendorId: "",
            businessType: "",
            address: "",
            address2: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
            loadingFareTypes: false,
            availableFareTypes: [],
            selectedFareTypes: [],
            nameLastChanged: Date.now(),
            duplicates: [],
            isSearching: false,
        }
    },
    methods: {
        createBusiness() {
            this.processing = true;

            let postBody = {
                name: this.name,
                customerId: this.customerId,
                vendorId: this.vendorId,
                businessType: this.businessType,
                address: this.address,
                address2: this.address2,
                city: this.city,
                state: this.state,
                postalCode: this.postalCode,
                country: this.country,
                fareTypes: this.selectedFareTypes
            };

            axios.post(`/admin/businesses/create`, postBody).then(response => {
                window.location.href = `/admin/businesses/${response.data.businessId}`;
            }).catch(error => {
                this.swalError(error.data.message);
            }).then(() => {
                this.processing = false;
            });
        },
        async setBusinessType() {
            this.loadingFareTypes = true;
            try {
                const body = (await axios.get('/admin/businesses/fareTypes', {params: {businessType: this.businessType}})).data;
                this.availableFareTypes = body.availableFareTypes;
                this.selectedFareTypes = body.selectedFareTypes;
                this.loadingFareTypes = false;
            } catch (error) {
            }
        },
        handleNameChange(e) {
            let val = this.name;
            if (val.length > 2) {
                this.isSearching = true;
                this.checkForDuplicates(val);
            }
        },
        async checkForDuplicates(val) {
            let res = await axios(`/admin/businesses/check-for-duplicates?query=${encodeURIComponent(val)}&autocomplete=true&searchusers=false`).catch((e) => {
                console.log(e);
                return undefined;
            });

            if (!res) return;

            let data = await res.data;
            if (!data) return;

            this.isSearching = false;

            if (data.length && data.length > 0) {
                this.duplicates = data;
            }
            else {
                this.duplicates = [];
            }
        }
    },
    mounted() {
        this.setBusinessType();
    },
    computed: {
        listStates() {
            return JSON.parse(this.states);
        },
        listCountries() {
            return JSON.parse(this.countries);
        },
        formIsInvalid() {
            return Object.keys(this.fields).some(key => {
                return this.fields[key].invalid;
            });
        }
    },
    components: {
        'checkbox': CheckboxComponent,
        'modal': ModalComponent,
        BoxLoader
    }
}
</script>

<style lang="scss" scoped>

</style>
