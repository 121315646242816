<template>
    <LineChart
        :chart-data="chartdata"
        :chart-options="chartoptions"
        :loading="loading"
    />
</template>

<script>
import {Line as LineChart} from 'vue-chartjs';
import { CategoryScale, LinearScale, PointElement, LineElement, Chart, TimeScale, Legend, Tooltip } from "chart.js";

export default {
    components: {
        LineChart
    },
    props: {
        chartdata: {},
        loading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            chartoptions: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 4,
                intersect: true,
                elements: {
                    line: {
                        borderWidth: 2,
                        pointRadius: 2,
                        tension: 0,
                        fill: false
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            title: function (tooltipItem) {
                                let label = tooltipItem[0].label;
                                if (/br/.test(label)) {
                                    return label.split(/br/).map(element => {
                                        return element.trim();
                                    });
                                } else {
                                    return label;
                                }
                            }
                        }
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            maxRotation: 0,
                            maxTicksLimit: 5,
                            callback: (_value, index) => {
                                let label = this.chartdata.labels[index];
                                if (/br/.test(label)) {
                                    return label.split(/br/).map(element => {
                                        return element.trim();
                                    });
                                } else {
                                    return label;
                                }
                            }
                        }
                    },

                    y: {
                        type: 'linear',
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 5,
                            stepSize: 1
                        }
                    }
                }
            },
        }
    },
    mounted() {
        Chart.register(CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Legend, Tooltip);
    }
}
</script>
