<template>
  <div class="modal is-active" :class="modalModifiers" v-if="newValue">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head" v-if="$slots.header">
        <p class="modal-card-title">
          <slot name="header" />
        </p>
        <button v-if="allowClose" class="delete" type="button" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body" :class="contentModifiers">
        <slot />
      </section>
      <footer class="modal-card-foot" v-if="$slots.footer">
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        modalModifiers: {
            type: Array,
            default: () => { return []; }
        },
        contentModifiers: {
            type: Array,
            default: () => { return []; }
        },
        allowClose: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            newValue: false
        };
    },
    methods: {
        closeModal() {
            if (!this.allowClose) return;
            this.newValue = false;
            this.$emit('input', this.newValue);
        }
    },
    mounted() {
        this.newValue = this.value
    },
    watch: {
        value(val) { this.newValue = val; }
    }
}
</script>

<style lang="scss" scoped>
.modal.allow-overflow {
    overflow: auto!important;
    .modal-background {
        position: fixed!important;
    }
    .modal-card {
        max-height: 100%!important;
        overflow: unset!important;
    }
    .modal-card-body {
        overflow: unset!important;
    }
}
.modal-card-foot {
    display: block;
}
</style>
