<template>
    <DoughnutChart
        :chart-data="chartdata"
        :chart-options="chartoptions"
        :loading="loading"
    />
</template>

<script>
import { Doughnut as DoughnutChart } from 'vue-chartjs';
import { Chart, ArcElement, Legend, Tooltip } from "chart.js";
import {round} from "lodash";

export default {
    components: {
        DoughnutChart
    },
    props: {
        chartdata: {},
        loading: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            chartoptions: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 2,
                elements: {
                    arc: {
                        borderWidth: 2,
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem, tooltipData) {
                                let label = tooltipItem.label;
                                let total = tooltipItem.dataset.data.reduce((acc, data) => acc + data, 0);
                                let currentValue = tooltipItem.raw;
                                let percentage = round((currentValue / total * 100), 2);
                                return `${label}: ${currentValue} (${percentage}%)`;
                            }
                        }
                    },
                    legend: {
                        position: 'right'
                    }
                }
            }
        }
    },
    mounted() {
        const NoDataPlugin = {
            id: 'no-data-plugin',
            afterDraw: function(chart) {
                if (chart.data.datasets.length === 0) {
                    // No data is present
                    const ctx = chart.ctx;
                    const width = chart.width;
                    const height = chart.height
                    chart.clear();

                    ctx.save();
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.font = "16px normal 'Helvetica Nueue'";
                    ctx.fillText('No data to display', width / 2, height / 2);
                    ctx.restore();
                }
            }
        };

        Chart.register(NoDataPlugin, ArcElement, Legend, Tooltip);

    }
}
</script>
