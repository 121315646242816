<template>
  <div class="box mb-0">
    <div class="columns is-variable is-2 is-multiline is-mobile primary-info" :class="{'has-additional-info': hasAdditional}">
      <div class="column is-narrow traveler-type">
        <span class="tag has-text-weight-semibold is-rounded is-size-7-half">
          <span class="fas fa-user-circle mr-1 has-text-grey"></span>
          <span>{{ traveler.travelerTypeLabel }}</span>
        </span>
      </div>
      <div class="column name">
        <span class="has-text-weight-semibold has-text-grey-dark is-capitalized">{{ traveler.firstName }} {{ traveler.middleName }} {{ traveler.lastName }}</span>
        <p v-if="traveler.tickets" class="is-size-7-half has-text-grey">
          <span class="has-text-weight-semibold">Ticket{{ traveler.tickets.length > 1 ? 's' : '' }}:</span>
          <span v-for="(ticket, ticketIndex) in traveler.tickets" :key="ticketIndex">
            <copy>{{ ticket.plateNumber }}-{{ ticket.ticketNumber }}</copy>
            <template v-if="ticketIndex < traveler.tickets.length - 1">
              <span>/&nbsp;</span>
            </template>
          </span>
        </p>
        <p v-if="traveler.seats && traveler.seats.length > 0" class="is-size-7-half has-text-grey">
          <span class="has-text-weight-semibold">Seat{{ traveler.seats.length > 1 ? 's' : '' }}:</span>
          <span v-for="(seat, seatIndex) in traveler.seats" :key="seatIndex">
            {{ seat.rowNumber }}{{ seat.seatLetter }}<span v-if="seatIndex < traveler.seats.length - 1">,</span>
          </span>
        </p>
        <p v-if="traveler.travelerType === 'INF'" class="is-size-7-half has-text-grey">
          <span class="has-text-weight-semibold">No Seats: </span>
          <span>infant on lap</span>
        </p>
      </div>
      <div class="column is-12-touch is-narrow-desktop info">
        <p class="is-size-7-half has-text-grey is-inline-block">{{ traveler.gender }}</p>
        <span class="is-size-7-half has-text-grey-light">&nbsp;|&nbsp;</span>
        <p class="is-size-7-half has-text-grey is-inline-block dob">{{ traveler.dob | formatDate('MMM. Do, YYYY') }}</p>
      </div>
    </div>
    <div class="columns is-multiline additional-info" v-if="hasAdditional">
      <div class="column is-3" v-if="traveler.hasFrequentFlyer">
        <p class="is-size-7 has-text-weight-semibold has-text-grey-dark">Frequent Flyer</p>
        <p class="is-size-7-half">
          <span>{{ traveler.frequentFlyerNumber }}</span>
          <span class="is-size-7 has-text-grey">({{ traveler.frequentFlyerProgram }})</span>
        </p>
      </div>
      <div class="column is-3" v-if="traveler.hasPassport">
        <p class="is-size-7 has-text-weight-semibold has-text-grey-dark">Passport</p>
        <p class="is-size-7-half">
          <span>{{ traveler.passportNumber }}</span>
          <span class="is-size-7 has-text-grey" style="white-space:nowrap;">({{ traveler.passportCountry }} | {{ traveler.passportExpiration | formatDate('MM/DD/YY')}})</span>
        </p>
      </div>
      <div class="column is-3" v-if="traveler.hasKnownTraveler">
        <p class="is-size-7 has-text-weight-semibold has-text-grey-dark">Known Traveler</p>
        <p class="is-size-7-half">{{ traveler.knownTravelerNumber }}</p>
      </div>
      <div class="column is-3" v-if="traveler.hasRedress">
        <p class="is-size-7 has-text-weight-semibold has-text-grey-dark">Redress</p>
        <p class="is-size-7-half">
          <span>{{ traveler.redressNumber }}</span>
          <span class="is-size-7 has-text-grey">({{ traveler.redressCountry }})</span>
        </p>
      </div>
      <div class="column is-12" v-if="traveler.hasSpecialAssistance">
        <p class="is-size-7 has-text-weight-semibold has-text-grey-dark">Special Assistance</p>
        <p class="is-size-7-half">{{ traveler.specialAssistance }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '@components/DataTable.vue';
import Copy from '@components/Copy/CopyComponent.vue';

export default {
  props: {
    traveler: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    hasAdditional() {
      return this.traveler.hasFrequentFlyer || this.traveler.hasPassport || this.traveler.hasKnownTraveler || this.traveler.hasRedress || this.traveler.hasSpecialAssistance;
    }
  },
  components: {
    DataTable,
    Copy
  }
}
</script>

<style lang="scss" scoped>
@import "bulma/sass/utilities/all";
.box {
  margin-top: -1px;
  padding: 0.75rem;
  .has-additional-info {
    border-bottom: solid 1px hsl(0, 0%, 96%);
  }
  .additional-info {
    padding-top: 0.5rem;
    .column {
      padding: 0rem 1rem;
      padding-bottom: 0.5rem;
    }
  }
  .column {
    .dob {
      width: 105px;
    }
  }
}
@include touch() {
  .box {
    padding: 1.25rem;
    .primary-info {
      .column {
        padding: 0;
      }
    }
    .additional-info {
      .column {
        padding: 0 0.25rem 0.25rem!important;
      }
    }
    .column {
      padding: 0 0.25rem!important;
      &.name {
        order: 1;
        padding-right: 0.5rem;
      }
      &.traveler-type {
        order: 2;
      }
      &.info {
        order: 3;
      }
    }
  }
}
</style>
