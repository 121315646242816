class Colors {

    constructor() {
        this.colors = [
            '#EF5350',
            '#AB47BC',
            '#5C6BC0',
            '#29B6F6',
            '#26A69A',
            '#9CCC65',
            '#FFEE58',
            '#FFA726',
            '#8D6E63',
            '#EC407A',
            '#7E57C2',
            '#42A5F5',
            '#26C6DA',
            '#66BB6A',
            '#D4E157',
            '#FFCA28',
            '#FF7043',
        ];
    }

    randomColor() {
        let chars = '0123456789ABCDEF';
        let color = [];
        for(let i = 0; i < 6; i++) {
            color[i] = chars.charAt(Math.random() * 15);
        }
        return `#${color.join('')}`;
    }

    getColor(i) {
        if(this.colors[i]) {
            return this.colors[i];
        } else {
            return this.randomColor();
        }
    }

}

export default new Colors();